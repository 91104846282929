import { useLocation } from "react-router-dom";
import Logo from "./Logo";
import Menu from "./Menu";

function Header() {
  const location = useLocation();

  return (
    <div className="header-container">
      <Logo />
      {/* <div className="slogan">
        <div className="text-0">
          <h1 className="head">Gedenk-Fahrradtour 2025</h1>
        </div>
        <div className="text-1">
          80 Jahre - Ende 2. Weltkrieg
          <br />
          Gründung der Vereinten Nationen
        </div>
      </div> */}
      <Menu currentPath={location.pathname} />
    </div>
  );
}

export default Header;
