import { useEffect, useState } from "react";
import * as edjsHTML from "editorjs-html";

const edjsParser = edjsHTML();

function Tour() {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "production"
          ? ""
          : `http://${window.location.hostname}:3011`
      }/content-tour`
    )
      .then((res) => res.json())
      .then((data) => {
        const rawData = data?.data?.attributes?.content;
        const parsedData = JSON.parse(rawData);
        if (parsedData) {
          const html = edjsParser.parse(parsedData);
          setContent(
            html.filter((block) => typeof block === "string").join("")
          );
        }

        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      <div className="Tour-main">
        {!isLoading ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : null}
      </div>
    </div>
  );
}

export default Tour;
