import { ReactSVG } from "react-svg";
import map from "../../assets/MapBig.svg";
import mapRoute from "../../assets/MapBigRoute.svg";
import arrowTeamD from "../../assets/icon_team_zinnoberrot_d.svg";
import komootD from "../../assets/icon_komoot_zinnoberrot.svg";
import arrowLocationD from "../../assets/icon_location_zinnoberrot_d.svg";
import arrowOLocationD from "../../assets/icon_offshore_location_zinnoberrot_d.svg";
import arrowEtappenD from "../../assets/icon_etappen_zinnoberrot_d.svg";
import arrowPoiD from "../../assets/icon_poi_zinnoberrot_d.svg";
import cross from "../../assets/icon_cross_aubergine.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import arrowRight from "../../assets/icon_arrow4_zinnoberrot.svg";
import arrowRightNC from "../../assets/icon_arrow4_zinnoberrot_no_circle.svg";
import routeTime from "../../assets/icon_etappen_zinnoberrot.svg";
import Slider from "react-touch-drag-slider";
import sources from "./sources.json";
import { Link } from "react-router-dom";

const etappeInfos = {
  e1: {
    komootSrc: "https://www.komoot.de/tour/1379361856",
  },
  e2: {
    komootSrc: "https://www.komoot.de/tour/1379365343",
  },
  e3: {
    komootSrc: "https://www.komoot.de/tour/1379372173",
  },
  e4: {
    komootSrc: "https://www.komoot.de/tour/1380091390",
  },
  e5: {
    komootSrc: "https://www.komoot.de/tour/1383272217",
  },
  e6: {
    komootSrc: "https://www.komoot.de/tour/1383280451",
  },
  e7: {
    komootSrc: "https://www.komoot.de/tour/1383289579",
  },
  e8: {
    komootSrc: "https://www.komoot.de/tour/1383292741",
  },
  e9: {
    komootSrc: "https://www.komoot.de/tour/1383392284",
  },
  e10: {
    komootSrc: "https://www.komoot.de/tour/1383451755",
  },
  e11: {
    komootSrc: "https://www.komoot.de/tour/1383788330",
  },
  e12: {
    komootSrc: "https://www.komoot.de/tour/1383790456",
  },
  e13: {
    komootSrc: "https://www.komoot.de/tour/1383791929",
  },
  e14: {
    komootSrc: "https://www.komoot.de/tour/1383793648",
  },
  e15: {
    komootSrc: "https://www.komoot.de/tour/1383794685",
  },
  e16: {
    komootSrc: "https://www.komoot.de/tour/1383798932",
  },
  e17: {
    komootSrc: "https://www.komoot.de/tour/1383799667",
  },
};

const routeConfig = {
  etappe0: {
    stageNumber: 0,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `-750 600 ${209 * 12} ${305 * 12}`,
    },
    stages: [
      {
        key: "etappe-1",
        position: {
          x: 428,
          y: 115,
          isRight: true,
        },
        stageNumber: 1,
        name: "Wismar",
      },
      {
        key: "etappe-2",
        position: {
          x: 287,
          y: 308,
          isRight: false,
        },
        stageNumber: 2,
        name: "Schwerin",
      },
      {
        key: "etappe-3",
        position: {
          x: 347,
          y: 573,
          isRight: true,
        },
        stageNumber: 3,
        name: "Dömitz",
      },
      {
        key: "etappe-4",
        position: {
          x: 617,
          y: 993,
          isRight: true,
        },
        stageNumber: 4,
        name: "Tangermünde",
      },
      {
        key: "etappe-5",
        position: {
          x: 437,
          y: 1353,
          isRight: false,
        },
        stageNumber: 5,
        name: "Schönebeck",
      },
      {
        key: "etappe-6",
        position: {
          x: 767,
          y: 1523,
          isRight: true,
        },
        stageNumber: 6,
        name: "Dessau",
      },
      {
        key: "etappe-7",
        position: {
          x: 850,
          y: 1868,
          isRight: false,
        },
        stageNumber: 7,
        name: "Wurzen",
      },
      {
        key: "etappe-8",
        position: {
          x: 1040,
          y: 2288,
          isRight: true,
        },
        stageNumber: 8,
        name: "Chemnitz",
      },
      {
        key: "etappe-9",
        position: {
          x: 1015,
          y: 2448,
          isRight: true,
        },
        stageNumber: 9,
        name: "Oberwiesenthal",
      },
      {
        key: "etappe-10",
        position: {
          x: 863,
          y: 2628,
          isRight: false,
        },
        stageNumber: 10,
        name: "Karlsbad",
      },
      {
        key: "etappe-11",
        position: {
          x: 1140,
          y: 2968,
          isRight: false,
        },
        stageNumber: 11,
        name: "Pilsen",
      },
      {
        key: "etappe-12",
        position: {
          x: 1604,
          y: 3138,
          isRight: true,
        },
        stageNumber: 12,
        name: "Pisek",
      },
      {
        key: "etappe-13",
        position: {
          x: 1610,
          y: 3368,
          isRight: false,
        },
        stageNumber: 13,
        name: "Budweis",
      },
      {
        key: "etappe-14",
        position: {
          x: 1530,
          y: 3588,
          isRight: true,
        },
        stageNumber: 14,
        name: "Horni Plana",
      },
      {
        key: "etappe-15",
        position: {
          x: 1570,
          y: 3888,
          isRight: false,
        },
        stageNumber: 15,
        name: "Linz",
      },
      {
        key: "etappe-16",
        position: {
          x: 1940,
          y: 3928,
          isRight: false,
        },
        stageNumber: 16,
        name: "Ybbs",
      },
      {
        key: "etappe-17",
        position: {
          x: 2320,
          y: 3857,
          isRight: false,
        },
        stageNumber: 17,
        name: "Tulln",
      },
      {
        key: "etappe-18",
        position: {
          x: 2540,
          y: 3897,
          isRight: true,
        },
        stageNumber: 18,
        name: "Wien",
      },
    ],
  },
  etappe1: {
    stageNumber: 1,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: "442 300 209 305",
    },
    stopps: [
      {
        key: "britische-panzer-marktplatz-wismar",
        position: {
          x: 238,
          y: 149,
          isRight: true,
        },
        type: "historical",
        shortName: "Marktplatz",
        images: [],
        poiKey: 3,
      },
      {
        key: "st-marienkirche",
        position: {
          x: 306,
          y: 149,
        },
        type: "historical",
        shortName: "St. Marienkirche",
        images: [],
        poiKey: 4,
      },
      {
        key: "georgenkirche",
        position: {
          x: 247,
          y: 174,
          isRight: true,
        },
        type: "touristic",
        shortName: "Georgenkirche",
        images: [],
        poiKey: 5,
      },
      {
        key: "treffen-montgomery-rokossowsky-stadtvilla-wismar",
        position: {
          x: 296,
          y: 174,
        },
        type: "historical",
        shortName: "Montgomery-Villa ",
        images: [],
        poiKey: 6,
      },
      {
        key: "denkort-klein-trebbow",
        position: {
          x: 175,
          y: 364,
          isRight: true,
        },
        type: "historical",
        shortName: "Teehaus",
        images: [],
        poiKey: 10,
      },
      {
        key: "krankenmorde-euthanasie",
        position: {
          x: 189,
          y: 522,
          isRight: true,
        },
        type: "touristic",
        shortName: "Krankenmorde",
        images: [],
        poiKey: 13,
      },
      {
        key: "t-34-denkmal-schelfwerder",
        position: {
          x: 250,
          y: 498,
          isRight: false,
        },
        type: "historical",
        shortName: "T34 Denkmal",
        images: [],
        poiKey: 14,
      },
      {
        key: "gedenkplatte-marianne-grunthal",
        position: {
          x: 250,
          y: 546,
        },
        type: "historical",
        shortName: "Marianne Grunthal",
        images: [],
        poiKey: 16,
      },
    ],
    pois: [
      {
        key: "unesco-weltkulturerbe-wismar-stralsund",
        position: {
          x: 285,
          y: 105,
          isRight: false,
        },
        type: "historical",
        shortName: "UNESCO Städte Wismar und Stralsund",
        images: [],
        poiKey: 0,
      },
      {
        key: "sowjetischer-ehrenfriedhof-wismar",
        position: {
          x: 245,
          y: 128,
          isRight: true,
        },
        type: "historical",
        shortName: "Sowj. Ehrenfriedhof",
        images: [],
        poiKey: 2,
      },
      {
        key: "schlagbaeume-ost-west",
        position: {
          x: 331,
          y: 128,
        },
        type: "historical",
        shortName: "Schlagbäume bei Kritzow",
        images: [],
        poiKey: 7,
      },
      {
        key: "weltkriegsbunker",
        position: {
          x: 309,
          y: 204,
        },
        type: "touristic",
        shortName: "Weltkriegsbunker",
        images: [],
        poiKey: 8,
      },
      {
        key: "treffen-montgomery-rokossowsky-schloss-hasenwinkel",
        position: {
          x: 423,
          y: 256,
        },
        type: "historical",
        shortName: "Schloss Hasenwinkel",
        images: [],
        poiKey: 9,
      },
      {
        key: "barber-ljaschenko-abkommen",
        position: {
          x: -30,
          y: 420,
          isRight: false,
        },
        type: "touristic",
        shortName: "Barber-Ljaschenko Abkommen",
        images: [],
        poiKey: 11,
      },
      {
        key: "alliierte-in-westmecklenburg",
        position: {
          x: 0,
          y: 450,
          isRight: false,
        },
        type: "historical",
        shortName: "Soldatentreffen Lützow",
        images: [],
        poiKey: 12,
      },
      {
        key: "juedischer-friedhof-bornhoevedstrasse",
        position: {
          x: 258,
          y: 525,
          isRight: false,
        },
        type: "historical",
        shortName: "Jüdischer Friedhof",
        images: [],
        poiKey: 15,
      },
    ],
    start: {
      flag: {
        position: {
          x: 261,
          y: 138,
        },
      },
      arrow: null,
      name: {
        position: {
          x: -124,
          y: -77,
        },
        text: "Wismar",
      },
      poi: {
        key: "kinder-uni-hochschule-wismar",
        position: {
          x: -8,
          y: -67,
        },
        type: "touristic",
        shortName: "Kinder Uni",
        images: [],
        poiKey: 1,
      },
    },
    end: {
      flag: {
        position: {
          x: 212,
          y: 510,
        },
      },
      arrow: {
        position: {
          x: 90,
          y: 90,
          degrees: -12,
        },
      },
      name: {
        position: {
          x: -94,
          y: 52,
        },
        text: "Schwerin",
      },
      poi: {
        key: "hauptbahnhof-schwerin",
        position: {
          x: 45,
          y: 61,
        },
        type: "touristic",
        shortName: "Hauptbahnhof",
        images: [],
        poiKey: 17,
      },
    },
  },
  etappe2: {
    stageNumber: 2,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `400 435 ${209 * 1.4} ${305 * 1.4}`,
    },
    stopps: [
      {
        key: "unesco-welterbestaette-schwerin",
        position: {
          x: 333,
          y: 122,
          isRight: false,
        },
        type: "historical",
        shortName: "UNESCO Residenzensembel",
        images: [],
        poiKey: 18,
      },
      {
        key: "sowjetischer-ehrenfriedhof-grabanlagen",
        position: {
          x: 268,
          y: 146,
          isRight: true,
        },
        type: "historical",
        shortName: "Sowj. Ehrenfriedhof",
        images: [],
        poiKey: 19,
      },
      {
        key: "soldatentreffen-stoerkanal-schwerin",
        position: {
          x: 340,
          y: 146,
          isRight: false,
        },
        type: "historical",
        shortName: "Soldatentreffen",
        images: [],
        poiKey: 21,
      },
      {
        key: "mahn-und-gedenkstaette-die-mutter",
        position: {
          x: 333,
          y: 169,
          isRight: false,
        },
        type: "historical",
        shortName: "Die Mutter",
        images: [],
        poiKey: 22,
      },
      {
        key: "mahn-und-gedenkstaette-woebbelin",
        position: {
          x: 328,
          y: 249,
          isRight: true,
        },
        type: "historical",
        shortName: "Gedenkstätte Wöbbelin",
        images: [],
        poiKey: 24,
      },
      {
        key: "ludwigslust-beerdigung",
        position: {
          x: 321,
          y: 381,
          isRight: true,
        },
        type: "historical",
        shortName: "Beerdigung Ludwigslust",
        images: [],
        poiKey: 25,
      },
      {
        key: "generalstreffen-schloss-ludwigslust",
        position: {
          x: 355,
          y: 390,
          isRight: false,
        },
        type: "historical",
        shortName: "Generalstreffen",
        images: [],
        poiKey: 26,
      },
      {
        key: "woosmer-hof-mit-kuno-karls",
        position: {
          x: 200,
          y: 449,
          isRight: false,
        },
        type: "historical",
        shortName: "Woosmer Hof",
        images: [],
        poiKey: 28,
      },
      {
        key: "rueterberg-museum-dorfrepublik",
        position: {
          x: 152,
          y: 479,
          isRight: true,
        },
        type: "historical",
        shortName: "Dorfrepublik",
        images: [],
        poiKey: 29,
      },
      {
        key: "sowjetischer-ehrenfriedhof-doemitz",
        position: {
          x: 231,
          y: 494,
          isRight: false,
        },
        type: "historical",
        shortName: "Sowj. Ehrenfriedhof",
        images: [],
        poiKey: 30,
      },
      {
        key: "unesco-biosphaerenreservat",
        position: {
          x: 239,
          y: 517,
          isRight: false,
        },
        type: "historical",
        shortName: "UNESCO Biosphärenreservat",
        images: [],
        poiKey: 35,
      },
      {
        key: "doemitz-autobruecke",
        position: {
          x: 178,
          y: 542,
          isRight: true,
        },
        type: "historical",
        shortName: "Autobrücke",
        images: [],
        poiKey: 36,
      },
      {
        key: "skywalk-eisenbahnbruecke",
        position: {
          x: 208,
          y: 562,
          isRight: true,
        },
        type: "historical",
        shortName: "Skywalk",
        images: [],
        poiKey: 37,
      },
    ],
    pois: [
      {
        key: "mahn-und-gedenkstaette-gruenes-tal",
        position: {
          x: 277,
          y: 172,
          isRight: true,
        },
        type: "historical",
        shortName: "Gedenkstätte Grünes Tal",
        images: [],
        poiKey: 20,
      },
      {
        key: "provisorische-kriegsgefangenlager-suelztorf",
        position: {
          x: 310,
          y: 213,
          isRight: true,
        },
        type: "historical",
        shortName: "Gefangenlager Sülztorf",
        images: [],
        poiKey: 23,
      },
      {
        key: "soldatentreffen-grabower-allee-ludwigslust",
        position: {
          x: 356,
          y: 424,
          isRight: false,
        },
        type: "historical",
        shortName: "Soldatentreffen",
        images: [],
        poiKey: 27,
      },
      {
        key: "aussenlager-neuengamme-sprengstoffwerk-doemitz",
        position: {
          x: 237,
          y: 542,
          isRight: false,
        },
        type: "historical",
        shortName: "Außenlager Neuengamme",
        images: [],
        poiKey: 31,
      },
      {
        key: "minenkrater-oestlich-der-elbe-bei-bleckede",
        position: {
          x: -3,
          y: 398,
          isRight: false,
        },
        type: "historical",
        shortName: "Minenkrater",
        images: [],
        poiKey: 33,
      },
      {
        key: "pontonbruecke-bleckede",
        position: {
          x: -26,
          y: 415,
          isRight: true,
        },
        type: "historical",
        shortName: "Pontonbrücke",
        images: [],
        poiKey: 34,
      },
      {
        key: "kapitulation-doenitz-montgomery",
        position: {
          x: -155,
          y: 508,
          isRight: false,
        },
        type: "historical",
        shortName: "Kapitulation Dönitz",
        images: [],
        poiKey: 32,
      },
    ],
    start: {
      flag: {
        position: {
          x: 293,
          y: 135,
        },
      },
      arrow: {
        position: {
          x: -130,
          y: -130,
          degrees: 8,
        },
      },
      name: {
        position: {
          x: -146,
          y: -49,
        },
        text: "Schwerin",
      },
      poi: {
        key: "hauptbahnhof-schwerin",
        position: {
          x: -9,
          y: -40,
        },
        type: "touristic",
        shortName: "Hauptbahnhof",
        images: [],
        text: "Das Ende unserer ersten Etappe wird der Hauptbahnhof Schwerin sein.",
        poiKey: 17,
      },
    },
    end: {
      flag: {
        position: {
          x: 192,
          y: 505,
        },
      },
      arrow: {
        position: {
          x: 70,
          y: 105,
          degrees: -6,
        },
      },
      name: {
        position: {
          x: 34,
          y: 70,
        },
        text: "Dömitz",
      },
      poi: {
        key: "rathaus-doemitz",
        position: {
          x: 142,
          y: 80,
        },
        type: "touristic",
        shortName: "Rathaus",
        images: [],
        text: "Am Rathaus Dömitz endet unsere zweite und startet unsere dritte von insgesamt 17 Etappen nach Wien.",
        poiKey: 38,
      },
    },
  },
  etappe3: {
    stageNumber: 3,
    config: {
      topBase: 2,
      leftBase: 790,
      topOffset: 0.5,
      leftOffset: -64.75,
      viewBox: `430 635 ${209 * 2} ${305 * 2}`,
    },
    stopps: [
      {
        key: "turmuhr-singer-veridas-wittenberge",
        position: {
          x: 514,
          y: 204,
          isRight: false,
        },
        type: "historical",
        shortName: "Turmuhr Wittenberge",
        images: [],
        poiKey: 39,
      },
      {
        key: "zerstoerte-haeuser-wittenberge",
        position: {
          x: 540,
          y: 244,
          isRight: false,
        },
        type: "historical",
        shortName: "Zerstörte Häuser",
        images: [],
        poiKey: 40,
      },
      {
        key: "gesprengte-elbbruecke-wittenberge",
        position: {
          x: 501,
          y: 247,
          isRight: true,
        },
        type: "historical",
        shortName: "Gesprengte Elbbrücke",
        images: [],
        poiKey: 41,
      },
      {
        key: "werben-elbe",
        position: {
          x: 614,
          y: 331,
          isRight: false,
        },
        type: "historical",
        shortName: "Werben",
        images: [],
        poiKey: 42,
      },
      {
        key: "fluechtlinige-in-raebel",
        position: {
          x: 619,
          y: 353,
          isRight: false,
        },
        type: "historical",
        shortName: "Flüchtlinige in Räbel",
        images: [],
        poiKey: 43,
      },
      {
        key: "sanda-kirche-artillerie-us",
        position: {
          x: 622,
          y: 375,
          isRight: false,
        },
        type: "historical",
        shortName: "Kirche Sandau",
        images: [],
        poiKey: 44,
      },
      {
        key: "verhandlungen-im-rathaus-stendal",
        position: {
          x: 544,
          y: 493,
          isRight: true,
        },
        type: "historical",
        shortName: "Verhandlungen in Stendal",
        images: [],
        poiKey: 45,
      },
      {
        key: "gesprengte-elbebruecke-tangermuende",
        position: {
          x: 612,
          y: 504,
          isRight: false,
        },
        type: "historical",
        shortName: "Gesprengte Elbbrücke",
        images: [],
        poiKey: 47,
      },
      {
        key: "befreiung-tangermuende",
        position: {
          x: 624,
          y: 527,
          isRight: false,
        },
        type: "historical",
        shortName: "Befreiung",
        images: [],
        poiKey: 48,
      },
    ],
    pois: [
      {
        key: "sowjetischer-ehrenfriedhof-stendal",
        position: {
          x: 550,
          y: 541,
          isRight: true,
        },
        type: "historical",
        shortName: "Sowj. Ehrenfriedhof",
        images: [],
        poiKey: 46,
      },
      {
        key: "gedenkstein-kriegstote",
        position: {
          x: 558,
          y: 561,
          isRight: true,
        },
        type: "historical",
        shortName: "Gedenkstein",
        images: [],
        poiKey: 49,
      },
    ],
    start: {
      flag: {
        position: {
          x: 316, // 306
          y: 121, // 95
        },
      },
      arrow: {
        position: {
          x: -136,
          y: -118,
          degrees: 4,
        },
      },
      name: {
        position: {
          x: -131,
          y: -37,
        },
        text: "Dömitz",
      },
      poi: {
        key: "rathaus-doemitz",
        position: {
          x: -21,
          y: -27,
        },
        type: "touristic",
        shortName: "Rathaus",
        images: [],
        poiKey: 38,
      },
    },
    end: {
      flag: {
        position: {
          x: 578,
          y: 530,
        },
      },
      arrow: {
        position: {
          x: -135,
          y: 72,
          degrees: 6,
        },
      },
      name: {
        position: {
          x: -165,
          y: 36,
        },
        text: "Tangermünde",
      },
      poi: {
        key: "rathaus-doemitz",
        position: {
          x: 37,
          y: 45,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 50,
      },
    },
  },
  etappe4: {
    stageNumber: 4,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `550 1060 ${209 * 1.5} ${305 * 1.5}`,
    },
    stopps: [
      {
        key: "deichbruch-fischbeck-und-kriegsende",
        position: {
          x: 392,
          y: 101,
          isRight: false,
        },
        type: "historical",
        shortName: "Deichbruch",
        images: [],
        poiKey: 51,
      },
      {
        key: "kloster-jerichow",
        position: {
          x: 408,
          y: 161,
          isRight: false,
        },
        type: "historical",
        shortName: "Kloster Jerichow",
        images: [],
        poiKey: 52,
      },
      {
        key: "sowjetischer-ehrenfriedhof-burg",
        position: {
          x: 286,
          y: 362,
          isRight: false,
        },
        type: "historical",
        shortName: "Sowj. Ehrenfriedhof Burg",
        images: [],
        poiKey: 61,
      },
      {
        key: "kz-aussenlager-magdeburg-rothensee",
        position: {
          x: 198,
          y: 402,
          isRight: true,
        },
        type: "historical",
        shortName: 'Außenlager "Magda"',
        images: [],
        poiKey: 62,
      },
      {
        key: "ersatzbruecke-zerstoerte-elbebruecke",
        position: {
          x: 226,
          y: 428,
          isRight: false,
        },
        type: "historical",
        shortName: "Strombrücke Magdeburg",
        images: [],
        poiKey: 67,
      },
      {
        key: "gedenkstein-hiroshima-nagasaki-magdeburg",
        position: {
          x: 226,
          y: 452,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkstein Hiroshima-Nagasaki",
        images: [],
        poiKey: 68,
      },
      {
        key: "magdeburger-dom",
        position: {
          x: 190,
          y: 465,
          isRight: true,
        },
        type: "historical",
        shortName: "Domplatte und Dom",
        images: [],
        poiKey: 69,
      },
      {
        key: "gedenktafel-17-juni-1953-magdeburg",
        position: {
          x: 201,
          y: 488,
          isRight: true,
        },
        type: "historical",
        shortName: 'Gedenktafel "17. Juni 1953"',
        images: [],
        poiKey: 71,
      },
      {
        key: "denkmal-ermordete-roma-und-sinti",
        position: {
          x: 240,
          y: 475,
          isRight: false,
        },
        type: "historical",
        shortName: "Denkmal Roma und Sinti",
        images: [],
        poiKey: 73,
      },
      {
        key: "feld-vereinten-nationen-westerhuesen",
        position: {
          x: 235,
          y: 532,
          isRight: true,
        },
        type: "historical",
        shortName: "Feld der Vereinten Nationen",
        images: [],
        poiKey: 75,
      },
      {
        key: "weltradladen",
        position: {
          x: 320,
          y: 535,
          isRight: false,
        },
        type: "historical",
        shortName: "Weltradladen",
        images: [],
        poiKey: 76,
      },
    ],
    pois: [
      {
        key: "sowjetischer-ehrenfriedhof-im-nordpark-magdeburg",
        position: {
          x: 241,
          y: 410,
          isRight: false,
        },
        type: "historical",
        shortName: "Sowj. Ehrenfriedhof",
        images: [],
        poiKey: 63,
      },
      {
        key: "einschliessung-magdeburg",
        position: {
          x: 192,
          y: 426,
          isRight: true,
        },
        type: "historical",
        shortName: "US Artillerie und Infanterie",
        images: [],
        poiKey: 64,
      },
      {
        key: "luftaufnahme-magdeburg",
        position: {
          x: 190,
          y: 446,
          isRight: true,
        },
        type: "historical",
        shortName: "Luftaufnahmen",
        images: [],
        poiKey: 66,
      },
      {
        key: "westfriedhof-magdeburg",
        position: {
          x: 220,
          y: 514,
          isRight: true,
        },
        type: "historical",
        shortName: "Westfriedhof",
        images: [],
        poiKey: 74,
      },
    ],
    start: {
      flag: {
        position: {
          x: 340,
          y: 80,
        },
      },
      arrow: {
        position: {
          x: 95,
          y: -80,
          degrees: 3,
        },
      },
      name: {
        position: {
          x: -240,
          y: -33,
        },
        text: "Tangermünde",
      },

      poi: {
        key: "rathaus-doemitz",
        position: {
          x: -40,
          y: -24,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 50,
      },
    },
    end: {
      flag: {
        position: {
          x: 305,
          y: 560,
        },
      },
      arrow: {
        position: {
          x: 180,
          y: 30,
          degrees: -20,
        },
      },
      name: {
        position: {
          x: -135,
          y: 40,
        },
        text: "Schönebeck",
      },
      poi: {
        key: "rathaus-schoenebeck",
        position: {
          x: 45,
          y: 50,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 77,
      },
    },
  },
  etappe5: {
    stageNumber: 5,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `710 1320 ${209 * 1.3} ${305 * 1.3}`,
    },
    stopps: [
      {
        key: "flakstellung-barby",
        position: {
          x: 103,
          y: 302,
          isRight: true,
        },
        type: "historical",
        shortName: "Flakstellung",
        images: [],
        poiKey: 84,
      },
      {
        key: "amerikanischer-brueckenkopf-flak-stellung-barby-elbe",
        position: {
          x: 110,
          y: 260,
          isRight: false,
        },
        type: "historical",
        shortName: "Truman Bridge",
        images: [],
        poiKey: 86,
      },
      {
        key: "zerbst-treffen",
        position: {
          x: 255,
          y: 299,
          isRight: false,
        },
        type: "historical",
        shortName: "Treffen in Zerbst",
        images: [],
        poiKey: 87,
      },
      {
        key: "junkers-flugzeugwerke",
        position: {
          x: 270,
          y: 352,
          isRight: true,
        },
        type: "historical",
        shortName: "Junkers Flugzeugwerke",
        images: [],
        poiKey: 89,
      },
      {
        key: "panzersperre-rosslau",
        position: {
          x: 310,
          y: 338,
          isRight: false,
        },
        type: "historical",
        shortName: "Panzersperre",
        images: [],
        poiKey: 90,
      },
      {
        key: "apollensdorf-treffen",
        position: {
          x: 575,
          y: 386,
          isRight: true,
        },
        type: "historical",
        shortName: "Treffen Apollensdorf",
        images: [],
        poiKey: 91,
      },
      {
        key: "neue-synagoge-dessau",
        position: {
          x: 320,
          y: 446,
          isRight: true,
        },
        type: "historical",
        shortName: "Neue Synagoge",
        images: [],
        poiKey: 97,
      },
      {
        key: "friedhof-3-dessau-rosslau",
        position: {
          x: 290,
          y: 470,
          isRight: true,
        },
        type: "historical",
        shortName: "Friedhof III",
        images: [],
        poiKey: 98,
      },
      {
        key: "friedensglocke",
        position: {
          x: 290,
          y: 494,
          isRight: true,
        },
        type: "historical",
        shortName: "Friedensglocke",
        images: [],
        poiKey: 99,
      },
      {
        key: "unesco-welterbestaette-bauhaus-dessau",
        position: {
          x: 358,
          y: 482,
          isRight: false,
        },
        type: "historical",
        shortName: "UNESCO Bauhaus",
        images: [],
        poiKey: 100,
      },
    ],
    pois: [
      {
        key: "luftbild-saalemuendung",
        position: {
          x: 64,
          y: 240,
          isRight: true,
        },
        type: "historical",
        shortName: "Luftbild Saalemündung",
        images: [],
        poiKey: 78,
      },
      {
        key: "bereitstellungsraum-brueckenkopf-barby",
        position: {
          x: 173,
          y: 281,
          isRight: false,
        },
        type: "historical",
        shortName: "Bereitstellung Brückenkopf",
        images: [],
        poiKey: 85,
      },
      {
        key: "befreiung-kommunisten-kriegsgefangenen",
        position: {
          x: 281,
          y: 321,
          isRight: false,
        },
        type: "historical",
        shortName: "Befreiung",
        images: [],
        poiKey: 88,
      },
      {
        key: "pratau-treffen",
        position: {
          x: 610,
          y: 420,
          isRight: true,
        },
        type: "historical",
        shortName: "Pratau Treffen",
        images: [],
        poiKey: 92,
      },
      {
        key: "wittenberg-treffen-zweier-armeen",
        position: {
          x: 600,
          y: 345,
          isRight: true,
        },
        type: "historical",
        shortName: "Armeentreffen",
        images: [],
        poiKey: 93,
      },
      {
        key: "welterbestaette-wittenberg",
        position: {
          x: 610,
          y: 325,
          isRight: true,
        },
        type: "historical",
        shortName: "UNESCO Welterbestätte",
        images: [],
        poiKey: 94,
      },
      {
        key: "calbe-kriegsgefangene",
        position: {
          x: 65,
          y: 345,
          isRight: true,
        },
        type: "historical",
        shortName: "Kriegsgefangene",
        images: [],
        poiKey: 96,
      },
      {
        key: "cobbelsdorf-treffen",
        position: {
          x: 565,
          y: 275,
          isRight: true,
        },
        type: "historical",
        shortName: "Delegationstreffen",
        images: [],
        poiKey: 95,
      },
      {
        key: "gesprengte-muldebruecke",
        position: {
          x: 325,
          y: 425,
          isRight: true,
        },
        type: "historical",
        shortName: "Gesprengte Muldebrücke",
        images: [],
        poiKey: 101,
      },
    ],
    start: {
      flag: {
        position: {
          x: 36,
          y: 189,
        },
      },
      arrow: {
        position: {
          x: -70,
          y: -145,
          degrees: -28,
        },
      },
      name: {
        position: {
          x: -212,
          y: -44,
        },
        text: "Schönebeck",
      },
      poi: {
        key: "rathaus-schoenebeck",
        position: {
          x: -35,
          y: -35,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 77,
      },
    },
    end: {
      flag: {
        position: {
          x: 312,
          y: 470,
        },
      },
      arrow: {
        position: {
          x: 10,
          y: 120,
          degrees: -2,
        },
      },
      name: {
        position: {
          x: -113,
          y: 50,
        },
        text: "Dessau",
      },
      poi: {
        key: "rathaus-dessau",
        position: {
          x: -2,
          y: 60,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 102,
      },
    },
  },
  etappe6: {
    stageNumber: 6,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `923 1540 ${209 * 1.4} ${305 * 1.4}`,
    },
    stopps: [
      {
        key: "filmmuseum-wolfen",
        position: {
          x: -24,
          y: 338,
          isRight: true,
        },
        type: "historical",
        shortName: "Filmmuseum Wolfen",
        images: [],
        poiKey: 110,
      },
      {
        key: "stars-and-stripes-aus-bettlaken",
        position: {
          x: 498,
          y: 394,
          isRight: true,
        },
        type: "historical",
        shortName: "Stars and Stripes",
        images: [],
        poiKey: 112,
      },
      {
        key: "historischer-handschlag-von-torgau",
        position: {
          x: 490,
          y: 434,
          isRight: true,
        },
        type: "historical",
        shortName: "Handschlag von Torgau",
        images: [],
        poiKey: 113,
      },
      {
        key: "robertson-silvaschko-treffen",
        position: {
          x: 496,
          y: 474,
          isRight: true,
        },
        type: "historical",
        shortName: "Robertson und Silvaschko",
        images: [],
        poiKey: 114,
      },
      {
        key: "verbruederungen-torgau",
        position: {
          x: 568,
          y: 434,
          isRight: false,
        },
        type: "historical",
        shortName: "Verbrüderung",
        images: [],
        poiKey: 117,
      },
      {
        key: "grab-joe-polowsky",
        position: {
          x: 536,
          y: 406,
          isRight: false,
        },
        type: "historical",
        shortName: "Grab v. Polowsky",
        images: [],
        poiKey: 119,
      },
      {
        key: "fort-zinna",
        position: {
          x: 535,
          y: 499,
          isRight: false,
        },
        type: "historical",
        shortName: "Fort Zinna",
        images: [],
        poiKey: 121,
      },
    ],
    pois: [
      {
        key: "pontonbruecke-halle",
        position: {
          x: -155,
          y: 465,
          isRight: false,
        },
        type: "historical",
        shortName: "Pontonbrücke Halle",
        images: [],
        poiKey: 115,
      },
      {
        key: "torgau-treffen-am-elbufer",
        position: {
          x: 572,
          y: 462,
          isRight: false,
        },
        type: "historical",
        shortName: "Treffen",
        images: [],
        poiKey: 116,
      },
      {
        key: "elbe-days",
        position: {
          x: 561,
          y: 482,
          isRight: false,
        },
        type: "historical",
        shortName: "Elbe-Days",
        images: [],
        poiKey: 118,
      },
      {
        key: "clanzschwitz-treffen-craig",
        position: {
          x: 641,
          y: 632,
          isRight: true,
        },
        type: "historical",
        shortName: "Patrouillentreffen I",
        images: [],
        poiKey: 122,
      },
      {
        key: "kreinitz-treffen ",
        position: {
          x: 681,
          y: 611,
          isRight: true,
        },
        type: "historical",
        shortName: "Patrouillentreffen II",
        images: [],
        poiKey: 123,
      },
      {
        key: "lorenzkirch-strehla",
        position: {
          x: 711,
          y: 651,
          isRight: true,
        },
        type: "historical",
        shortName: "Soldatentreffen",
        images: [],
        poiKey: 124,
      },
    ],
    start: {
      flag: {
        position: {
          x: -42,
          y: 89,
        },
      },
      arrow: {
        position: {
          x: 200,
          y: -60,
          degrees: -8,
        },
      },
      name: {
        position: {
          x: -64,
          y: -56,
        },
        text: "Dessau",
      },
      poi: {
        key: "rathaus-dessau",
        position: {
          x: 45,
          y: -48,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 102,
      },
    },
    end: {
      flag: {
        position: {
          x: 222,
          y: 560,
        },
      },
      arrow: {
        position: {
          x: 50,
          y: 62,
          degrees: -4,
        },
      },
      name: {
        position: {
          x: -107,
          y: 40,
        },
        text: "Wurzen",
      },
      poi: {
        key: "ringelnatzbrunnen",
        position: {
          x: 7,
          y: 50,
        },
        type: "historical",
        shortName: "Ringelnatzbrunnen",
        images: [],
        poiKey: 126,
      },
    },
  },
  etappe7: {
    stageNumber: 7,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `1053 1820 ${209 * 1.75} ${305 * 1.75}`,
    },
    stopps: [
      {
        key: "wurzen-befreiung",
        position: {
          x: 103,
          y: 111,
          isRight: false,
        },
        type: "historical",
        shortName: "Befreiung",
        images: [],
        poiKey: 127,
      },
      {
        key: "muldebruecke-wurzen",
        position: {
          x: 31,
          y: 111,
          isRight: true,
        },
        type: "historical",
        shortName: "Muldebrücke",
        images: [],
        poiKey: 128,
      },
      {
        key: "gedenkstein-wurzen-kz-marsch",
        position: {
          x: 94,
          y: 135,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkstein KZ Marsch",
        images: [],
        poiKey: 129,
      },
      {
        key: "eisenbahnbruecke-wurzen",
        position: {
          x: 41,
          y: 135,
          isRight: true,
        },
        type: "historical",
        shortName: "Eisenbahnbrücke",
        images: [],
        poiKey: 130,
      },
      {
        key: "treffen-muldebruecke-penig",
        position: {
          x: 88,
          y: 535,
          isRight: true,
        },
        type: "historical",
        shortName: "Muldebrücke",
        images: [],
        poiKey: 141,
      },
    ],
    pois: [
      {
        key: "drei-patrouillien-suchen-kontakt",
        position: {
          x: 81,
          y: 205,
          isRight: true,
        },
        type: "historical",
        shortName: "Die 3 Patrouillien",
        images: [],
        poiKey: 131,
      },
      {
        key: "gedenktafel-begegnung-rochlitz",
        position: {
          x: 168,
          y: 440,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenktafel Rochlitz",
        images: [],
        poiKey: 139,
      },
      {
        key: "abzugbesprechung-rochlitz",
        position: {
          x: 142,
          y: 435,
          isRight: true,
        },
        type: "historical",
        shortName: "Abzugsbesprechung",
        images: [],
        poiKey: 140,
      },
      {
        key: "offizierstreffen-waldenburg",
        position: {
          x: 5,
          y: 565,
          isRight: true,
        },
        type: "historical",
        shortName: "Offizierstreffen",
        images: [],
        poiKey: 142,
      },
      {
        key: "mittweida",
        position: {
          x: 195,
          y: 485,
          isRight: false,
        },
        type: "historical",
        shortName: "Mittweida",
        images: [],
        poiKey: 143,
      },
    ],
    start: {
      flag: {
        position: {
          x: 56,
          y: 99,
        },
      },
      arrow: {
        position: {
          x: 20,
          y: -108,
          degrees: -6,
        },
      },
      name: {
        position: {
          x: -67,
          y: -46,
        },
        text: "Wurzen",
      },
      poi: {
        key: "ringelnatzbrunnen",
        position: {
          x: 45,
          y: -37,
        },
        type: "historical",
        shortName: "Ringelnatzbrunnen",
        images: [],
        poiKey: 126,
      },
    },
    end: {
      flag: {
        position: {
          x: 170,
          y: 560,
        },
      },
      arrow: {
        position: {
          x: -24,
          y: 58,
          degrees: 8,
        },
      },
      name: {
        position: {
          x: -163,
          y: 30,
        },
        text: "Chemnitz",
      },
      poi: {
        key: "rathaus-chemnitz",
        position: {
          x: -22,
          y: 40,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 153,
      },
    },
  },
  etappe8: {
    stageNumber: 8,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `1033 2205 ${209 * 0.9} ${305 * 0.9}`,
    },
    stopps: [
      {
        key: "gedenkstein-vereinte-nationen-chemnitz",
        position: {
          x: 91,
          y: 130,
          isRight: true,
        },
        type: "historical",
        shortName: "UN Gedenkstein",
        images: [],
        poiKey: 154,
      },
      {
        key: "kulturhauptstadt-europas-chemnitz",
        position: {
          x: 162,
          y: 130,
          isRight: false,
        },
        type: "historical",
        shortName: "Kulturhauptstadt Europas",
        images: [],
        poiKey: 156,
      },
      {
        key: "freie-republik-schwarzenberg",
        position: {
          x: -32,
          y: 400,
          isRight: true,
        },
        type: "historical",
        shortName: "Schwarzenberg",
        images: [],
        poiKey: 157,
      },
      {
        key: "fichtelberg-friedensglocke",
        position: {
          x: 52,
          y: 478,
          isRight: true,
        },
        type: "historical",
        shortName: "Friedensglocke",
        images: [],
        poiKey: 158,
      },
      {
        key: "basislager-fichtelberg",
        position: {
          x: 59,
          y: 501,
          isRight: true,
        },
        type: "historical",
        shortName: "Basislager",
        images: [],
        poiKey: 161,
      },
    ],
    pois: [
      {
        key: "luftbild-chemnitz",
        position: {
          x: 157,
          y: 155,
          isRight: false,
        },
        type: "historical",
        shortName: "Luftbild",
        images: [],
        poiKey: 155,
      },
      {
        key: "carlsfeld-sgt-staffort",
        position: {
          x: -257,
          y: 455,
          isRight: false,
        },
        type: "historical",
        shortName: "Carlsfeld Sgt Staffort",
        images: [],
        poiKey: 159,
      },
      {
        key: "gedenkstein-deportationen-kriegsende",
        position: {
          x: -267,
          y: 435,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkstein",
        images: [],
        poiKey: 160,
      },
    ],
    start: {
      flag: {
        position: {
          x: 116,
          y: 119,
        },
      },
      arrow: {
        position: {
          x: -60,
          y: -114,
          degrees: -12,
        },
      },
      name: {
        position: {
          x: -195,
          y: -47,
        },
        text: "Chemnitz",
      },
      poi: {
        key: "rathaus-chemnitz",
        position: {
          x: -54,
          y: -37,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 153,
      },
    },
    end: {
      flag: {
        position: {
          x: 84,
          y: 490,
        },
      },
      arrow: {
        position: {
          x: 15,
          y: 115,
          degrees: 8,
        },
      },
      name: {
        position: {
          x: -228,
          y: 61,
        },
        text: "Oberwiesenthal",
      },
      poi: {
        key: "rathaus-oberwiesenthal",
        position: {
          x: 2,
          y: 70,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 162,
      },
    },
  },
  etappe9: {
    stageNumber: 9,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `933 2330 ${209 * 1.0} ${305 * 1.0}`,
    },
    stopps: [
      {
        key: "gedenkstein-befreiung-armee-karlsbad",
        position: {
          x: 300,
          y: 512,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkstein",
        images: [],
        poiKey: 175,
      },
    ],
    pois: [
      {
        key: "UNESCO-montanregion-erzgebirge-krusnohori",
        position: {
          x: 337,
          y: 355,
          isRight: false,
        },
        type: "historical",
        shortName: "UNESCO Montanregion",
        images: [],
        poiKey: 173,
      },
      {
        key: "abzug-us-army-karlsbad",
        position: {
          x: 238,
          y: 516,
          isRight: true,
        },
        type: "historical",
        shortName: "Abzug der US Army",
        images: [],
        poiKey: 174,
      },
    ],
    start: {
      flag: {
        position: {
          x: 326,
          y: 150,
        },
      },
      arrow: {
        position: {
          x: -60,
          y: -124,
          degrees: 4,
        },
      },
      name: {
        position: {
          x: -270,
          y: -47,
        },
        text: "Oberwiesenthal",
      },
      poi: {
        key: "rathaus-oberwiesenthal",
        position: {
          x: -44,
          y: -37,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 162,
      },
    },
    end: {
      flag: {
        position: {
          x: 254,
          y: 500,
        },
      },
      arrow: {
        position: {
          x: -25,
          y: 105,
          degrees: -8,
        },
      },
      name: {
        position: {
          x: -98,
          y: 61,
        },
        text: "Karlsbad",
      },
      poi: {
        key: "glas-obelisk-karlsbad",
        position: {
          x: 38,
          y: 70,
        },
        type: "historical",
        shortName: "Glas Obelisk",
        images: [],
        poiKey: 176,
      },
    },
  },
  etappe10: {
    stageNumber: 10,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `913 2440 ${209 * 1.7} ${305 * 1.7}`,
    },
    stopps: [
      {
        key: "familiengeschichte-1",
        position: {
          x: 454,
          y: 334,
          isRight: false,
        },
        type: "historical",
        shortName: "Familiengeschichte I",
        images: [],
        poiKey: 177,
      },
      {
        key: "familiengeschichte-2",
        position: {
          x: 466,
          y: 356,
          isRight: false,
        },
        type: "historical",
        shortName: "Familiengeschichte II",
        images: [],
        poiKey: 178,
      },
      {
        key: "befreiung-pilsen-patton",
        position: {
          x: 584,
          y: 527,
          isRight: false,
        },
        type: "historical",
        shortName: "Befreiung",
        images: [],
        poiKey: 184,
      },
      {
        key: "general-patton-museum-pilsen",
        position: {
          x: 550,
          y: 543,
          isRight: true,
        },
        type: "historical",
        shortName: "General Patton Museum",
        images: [],
        poiKey: 185,
      },
      {
        key: "patton-general-denkmal",
        position: {
          x: 614,
          y: 549,
          isRight: false,
        },
        type: "historical",
        shortName: "Denkmal General Patton",
        images: [],
        poiKey: 186,
      },
    ],
    pois: [
      {
        key: "gedenkplatte-nove-sedlu",
        position: {
          x: 247,
          y: 162,
          isRight: true,
        },
        type: "historical",
        shortName: "Gedenkplatte Nove Sedlu",
        images: [],
        poiKey: 180,
      },
      {
        key: "gedenken-us-befreiung",
        position: {
          x: 267,
          y: 182,
          isRight: true,
        },
        type: "historical",
        shortName: "Gedenkplatte Befreiung",
        images: [],
        poiKey: 181,
      },
      {
        key: "ledce-u-bodovy-gedenkstein",
        position: {
          x: 574,
          y: 507,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkstein Ledce u Bodovy",
        images: [],
        poiKey: 182,
      },
    ],
    start: {
      flag: {
        position: {
          x: 285,
          y: 130,
        },
      },
      arrow: {
        position: {
          x: 50,
          y: -120,
          degrees: 6,
        },
      },
      name: {
        position: {
          x: -110,
          y: -57,
        },
        text: "Karlsbad",
      },
      poi: {
        key: "glas-obelisk-karlsbad",
        position: {
          x: 27,
          y: -47,
        },
        type: "historical",
        shortName: "Glas Obelisk",
        images: [],
        poiKey: 176,
      },
    },
    end: {
      flag: {
        position: {
          x: 570,
          y: 550,
        },
      },
      arrow: {
        position: {
          x: 85,
          y: 45,
          degrees: -12,
        },
      },
      name: {
        position: {
          x: -118,
          y: 7,
        },
        text: "Pilsen",
      },
      poi: {
        key: "rathaus-pilsen",
        position: {
          x: 68,
          y: 17,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 187,
      },
    },
  },
  etappe11: {
    stageNumber: 11,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `1153 2790 ${209 * 1} ${305 * 1}`,
    },
    stopps: [
      {
        key: "synagoge-juedisch",
        position: {
          x: 213,
          y: 158,
          isRight: true,
        },
        type: "historical",
        shortName: "Große Synagoge",
        images: [],
        poiKey: 189,
      },
      {
        key: "brasy-gedenkstein-befreiung-begegnung",
        position: {
          x: 332,
          y: 155,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkplatte Brasy",
        images: [],
        poiKey: 190,
      },
      {
        key: "muesum-demarkation-linie-rokycany",
        position: {
          x: 343,
          y: 199,
          isRight: false,
        },
        type: "historical",
        shortName: "Demarkationslinie Museum",
        images: [],
        poiKey: 191,
      },
      {
        key: "vojna-arbeitslager-kommunismus",
        position: {
          x: 861,
          y: 205,
          isRight: false,
        },
        type: "historical",
        shortName: "Arbeitslager Vojna",
        images: [],
        poiKey: 200,
      },
      {
        key: "milin-denkmal",
        position: {
          x: 911,
          y: 252,
          isRight: false,
        },
        type: "historical",
        shortName: "Denkmal Milin",
        images: [],
        poiKey: 203,
      },
      {
        key: "pisek-bruecke-moldau-treffpunkt-btl",
        position: {
          x: 1051,
          y: 512,
          isRight: false,
        },
        type: "historical",
        shortName: "Vorstoß 3. US Armee",
        images: [],
        poiKey: 204,
      },
      {
        key: "befreiung-pisek",
        position: {
          x: 981,
          y: 512,
          isRight: true,
        },
        type: "historical",
        shortName: "Befreiung 3. US Armee",
        images: [],
        poiKey: 205,
      },
      {
        key: "sowjetisches-soldatengrab",
        position: {
          x: 1043,
          y: 536,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkstein",
        images: [],
        poiKey: 206,
      },
    ],
    pois: [
      {
        key: "gedenkplatt-stahlavy-befreiung-us-army",
        position: {
          x: 290,
          y: 206,
          isRight: true,
        },
        type: "historical",
        shortName: "Gedenkplatte Stahlavy",
        images: [],
        poiKey: 188,
      },
      {
        key: "gedenkplatte-nepomuk",
        position: {
          x: 493,
          y: 406,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkplatte Nepomuk",
        images: [],
        poiKey: 207,
      },
      {
        key: "gedenkplatte-lnare",
        position: {
          x: 583,
          y: 436,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkplatte Lnare",
        images: [],
        poiKey: 208,
      },
      {
        key: "gedenktafel-blatna",
        position: {
          x: 653,
          y: 456,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenktafel Baltna",
        images: [],
        poiKey: 209,
      },
      {
        key: "befreiung-sedlice",
        position: {
          x: 703,
          y: 476,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkplatte Sedlice",
        images: [],
        poiKey: 210,
      },
    ],
    start: {
      flag: {
        position: {
          x: 238,
          y: 145,
        },
      },
      arrow: {
        position: {
          x: 50,
          y: -125,
          degrees: -12,
        },
      },
      name: {
        position: {
          x: -112,
          y: -57,
        },
        text: "Pilsen",
      },
      poi: {
        key: "rathaus-pilsen",
        position: {
          x: -19,
          y: -47,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 187,
      },
    },
    end: {
      flag: {
        position: {
          x: 1005,
          y: 500,
        },
      },
      arrow: {
        position: {
          x: 25,
          y: 95,
          degrees: -18,
        },
      },
      name: {
        position: {
          x: -83,
          y: 57,
        },
        text: "Pisek",
      },
      poi: {
        key: "rathaus-pisek",
        position: {
          x: 3,
          y: 67,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 211,
      },
    },
  },
  etappe12: {
    stageNumber: 12,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `1403 2930 ${209 * 1.3} ${305 * 1.3}`,
    },
    stopps: [
      {
        key: "gestuet-mit-denkmal",
        position: {
          x: 424,
          y: 184,
          isRight: false,
        },
        type: "historical",
        shortName: "Gestüt mit Denkmal",
        images: [],
        poiKey: 219,
      },
      {
        key: "soldaten-treffen-us-rote-armee",
        position: {
          x: 382,
          y: 184,
          isRight: true,
        },
        type: "historical",
        shortName: "Denkmal Soldatentreffen",
        images: [],
        poiKey: 221,
      },
      {
        key: "denkmal-der-synagoge-budweis",
        position: {
          x: 547,
          y: 490,
          isRight: true,
        },
        type: "historical",
        shortName: "Synagoge",
        images: [],
        poiKey: 223,
      },
    ],
    pois: [
      {
        key: "us-hauptquartier",
        position: {
          x: 330,
          y: 161,
          isRight: true,
        },
        type: "historical",
        shortName: "Gedenkplatte Strakonice",
        images: [],
        poiKey: 218,
      },
      {
        key: "kapitulation-kaserne-pisek",
        position: {
          x: 310,
          y: 135,
          isRight: true,
        },
        type: "historical",
        shortName: "Kapitulation",
        images: [],
        poiKey: 222,
      },
    ],
    start: {
      flag: {
        position: {
          x: 394,
          y: 145,
        },
      },
      arrow: {
        position: {
          x: 0,
          y: -135,
          degrees: -14,
        },
      },
      name: {
        position: {
          x: -82,
          y: -60,
        },
        text: "Pisek",
      },
      poi: {
        key: "rathaus-pisek",
        position: {
          x: 4,
          y: -50,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 211,
      },
    },
    end: {
      flag: {
        position: {
          x: 556,
          y: 510,
        },
      },
      arrow: {
        position: {
          x: 15,
          y: 95,
          degrees: 12,
        },
      },
      name: {
        position: {
          x: -118,
          y: 57,
        },
        text: "Budweis",
      },
      poi: {
        key: "rathaus-budweis",
        position: {
          x: 10,
          y: 67,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 224,
      },
    },
  },
  etappe13: {
    stageNumber: 13,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `1375 3150 ${209 * 1.1} ${305 * 1.1}`,
    },
    stopps: [
      {
        key: "UNESCO-altstadt-krumlov",
        position: {
          x: 714,
          y: 157,
          isRight: false,
        },
        type: "historical",
        shortName: "UNESCO Altstadt Krumau",
        images: [],
        poiKey: 225,
      },
      {
        key: "budweistor-krumau",
        position: {
          x: 520,
          y: 389,
          isRight: true,
        },
        type: "historical",
        shortName: "Budweistor",
        images: [],
        poiKey: 227,
      },
      {
        key: "lipno-stausee-flucht",
        position: {
          x: 320,
          y: 520,
          isRight: true,
        },
        type: "historical",
        shortName: "Stausee Lipno",
        images: [],
        poiKey: 230,
      },
      {
        key: "stifter-adalbert-geburtshaus",
        position: {
          x: 394,
          y: 520,
          isRight: false,
        },
        type: "historical",
        shortName: "Geburtshaus Adalbert Stifter",
        images: [],
        poiKey: 231,
      },
    ],
    pois: [
      {
        key: "gedenkstein-demarkationslinie",
        position: {
          x: 614,
          y: 207,
          isRight: true,
        },
        type: "historical",
        shortName: "Denkmal Demarkationslinie",
        images: [],
        poiKey: 226,
      },
      {
        key: "denkmal-us-piloten",
        position: {
          x: 525,
          y: 440,
          isRight: false,
        },
        type: "historical",
        shortName: "Denkmal US Piloten",
        images: [],
        poiKey: 228,
      },
      {
        key: "amerikanische-piloten-denkmal",
        position: {
          x: 795,
          y: 632,
          isRight: false,
        },
        type: "historical",
        shortName: "Pilotendenkmal",
        images: [],
        poiKey: 229,
      },
    ],
    start: {
      flag: {
        position: {
          x: 667,
          y: 145,
        },
      },
      arrow: {
        position: {
          x: -110,
          y: -135,
          degrees: -4,
        },
      },
      name: {
        position: {
          x: -114,
          y: -60,
        },
        text: "Budweis",
      },
      poi: {
        key: "rathaus-budweis",
        position: {
          x: 14,
          y: -50,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 224,
      },
    },
    end: {
      flag: {
        position: {
          x: 346,
          y: 510,
        },
      },
      arrow: {
        position: {
          x: -75,
          y: 95,
          degrees: -24,
        },
      },
      name: {
        position: {
          x: -150,
          y: 57,
        },
        text: "Horni Plana",
      },
      poi: {
        key: "rathaus-horni-plana",
        position: {
          x: 28,
          y: 67,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 232,
      },
    },
  },
  etappe14: {
    stageNumber: 14,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `1275 3320 ${209 * 1.5} ${305 * 1.5}`,
    },
    stopps: [
      {
        key: "historisches-linz-ns-zeit",
        position: {
          x: 637,
          y: 500,

          isRight: false,
        },
        type: "historical",
        shortName: "Historische Aufnahmen 1939-45",
        images: [],
        poiKey: 240,
      },
      {
        key: "sektoren-wien-alliierte-verwaltung",
        position: {
          x: 669,
          y: 521,
          isRight: false,
        },
        type: "historical",
        shortName: "Befreiung",
        images: [],
        poiKey: 241,
      },
    ],
    pois: [],
    start: {
      flag: {
        position: {
          x: 472,
          y: 116,
        },
      },
      arrow: {
        position: {
          x: -50,
          y: -115,
          degrees: 24,
        },
      },
      name: {
        position: {
          x: -124,
          y: -46,
        },
        text: "Horni Plana",
      },
      poi: {
        key: "rathaus-horni-plana",
        position: {
          x: 56,
          y: -38,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 232,
      },
    },
    end: {
      flag: {
        position: {
          x: 626,
          y: 525,
        },
      },
      arrow: {
        position: {
          x: 75,
          y: 65,
          degrees: -54,
        },
      },
      name: {
        position: {
          x: -85,
          y: 4,
        },
        text: "Linz",
      },
      poi: {
        key: "linz-neues-rathaus",
        position: {
          x: 58,
          y: 14,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 242,
      },
    },
  },
  etappe15: {
    stageNumber: 15,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `1500 3500 ${209 * 1.3} ${305 * 1.3}`,
    },
    stopps: [
      {
        key: "mauthausen-konzentrationslager-gedenkort",
        position: {
          x: 457,
          y: 345,
          isRight: true,
        },
        type: "historical",
        shortName: "Befreiung KZ Mauthausen",
        images: [],
        poiKey: 243,
      },
      {
        key: "gedenkstaette-gusen",
        position: {
          x: 487,
          y: 312,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkstätte KZ Gusen",
        images: [],
        poiKey: 246,
      },
    ],
    pois: [
      {
        key: "kapitulation-heeresgruppe-sued",
        position: {
          x: 637,
          y: 512,
          isRight: false,
        },
        type: "historical",
        shortName: "Kapitulation Schloss Rothschild",
        images: [],
        poiKey: 247,
      },
    ],
    start: {
      flag: {
        position: {
          x: 292,
          y: 294,
        },
      },
      arrow: {
        position: {
          x: -20,
          y: -95,
          degrees: 4,
        },
      },
      name: {
        position: {
          x: -75,
          y: 2,
        },
        text: "Linz",
      },
      poi: {
        key: "linz-neues-rathaus",
        position: {
          x: 48,
          y: 12,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 242,
      },
    },
    end: {
      flag: {
        position: {
          x: 862,
          y: 325,
        },
      },
      arrow: {
        position: {
          x: 35,
          y: 0,
          degrees: 24,
        },
        up: true,
      },
      name: {
        position: {
          x: -23,
          y: -46,
        },
        text: "Ybbs",
      },
      poi: {
        key: "rathaus-ybbs",
        position: {
          x: 58,
          y: -38,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 250,
      },
    },
  },
  etappe16: {
    stageNumber: 16,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `1850 3450 ${209 * 1.3} ${305 * 1.3}`,
    },
    stopps: [
      {
        key: "melk-gedenkstaette-konzentrationslager",
        position: {
          x: 365,
          y: 355,
          isRight: false,
        },
        type: "historical",
        shortName: "Gedenkstätte KZ Melk",
        images: [],
        poiKey: 251,
      },
      {
        key: "UNESCO-kloster-melk",
        position: {
          x: 390,
          y: 330,
          isRight: false,
        },
        type: "historical",
        shortName: "UNESCO Kloster Melk",
        images: [],
        poiKey: 252,
      },
    ],
    pois: [
      {
        key: "friedenstadt-erlauf-general",
        position: {
          x: 312,
          y: 422,
          isRight: false,
        },
        type: "historical",
        shortName: "Generalstreffen",
        images: [],
        poiKey: 248,
      },
    ],
    start: {
      flag: {
        position: {
          x: 252,
          y: 406,
        },
      },
      arrow: {
        position: {
          x: -100,
          y: -55,
          degrees: -48,
        },
      },
      name: {
        position: {
          x: -123,
          y: 42,
        },
        text: "Ybbs",
      },
      poi: {
        key: "rathaus-ybbs",
        position: {
          x: -42,
          y: 50,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 250,
      },
    },
    end: {
      flag: {
        position: {
          x: 802,
          y: 305,
        },
      },
      arrow: {
        position: {
          x: 35,
          y: 40,
          degrees: 44,
        },
        up: true,
      },
      name: {
        position: {
          x: -83,
          y: 2,
        },
        text: "Tulln",
      },
      poi: {
        key: "rathaus-tulln",
        position: {
          x: 48,
          y: 10,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 257,
      },
    },
  },
  etappe17: {
    stageNumber: 17,
    config: {
      topBase: 2,
      leftBase: 915,
      topOffset: 0.5,
      leftOffset: -51.75,
      viewBox: `2100 3540 ${209 * 0.7} ${305 * 0.7}`,
    },
    stopps: [
      {
        key: "schlacht-wien-karte-tulln",
        position: {
          x: 512,
          y: 338,
          isRight: false,
        },
        type: "historical",
        shortName: "Schlacht um Wien",
        images: [],
        poiKey: 256,
      },
      {
        key: "unesco-schoenbrunn-heritage",
        position: {
          x: 904,
          y: 415,
          isRight: true,
        },
        type: "historical",
        shortName: "UNESCO Schloss Schönbrunn",
        images: [],
        poiKey: 258,
      },
      {
        key: "befreiungsmuseum-wien",
        position: {
          x: 952,
          y: 389,
          isRight: false,
        },
        type: "historical",
        shortName: "Befreiungsmuseum",
        images: [],
        poiKey: 260,
      },
      {
        key: "unesco-vienna-city",
        position: {
          x: 961,
          y: 415,
          isRight: false,
        },
        type: "historical",
        shortName: "UNESCO hist. Innenstadt",
        images: [],
        poiKey: 261,
      },
      {
        key: "befreiungsdenkmal-rote-armee-wien",
        position: {
          x: 904,
          y: 460,
          isRight: true,
        },
        type: "historical",
        shortName: "Sowj. Ehrenmal",
        images: [],
        poiKey: 262,
      },
      {
        key: "kinder-universitaet-wien",
        position: {
          x: 964,
          y: 460,
          isRight: false,
        },
        type: "historical",
        shortName: "Kinderuni",
        images: [],
        poiKey: 266,
      },
    ],
    pois: [
      {
        key: "flak-hochbunker-wien",
        position: {
          x: 904,
          y: 443,
          isRight: true,
        },
        type: "historical",
        shortName: "Flak-Hochbunker",
        images: [],
        poiKey: 259,
      },
      {
        key: "alliierte-verwaltungskontrolle-oesterreich-wien-linz",
        position: {
          x: 972,
          y: 443,

          isRight: false,
        },
        type: "historical",
        shortName: "Aufteilung in vier Sektoren",
        images: [],
        poiKey: 263,
      },
    ],
    start: {
      flag: {
        position: {
          x: 452,
          y: 296,
        },
      },
      arrow: {
        position: {
          x: -100,
          y: -95,
          degrees: -58,
        },
      },
      name: {
        position: {
          x: -83,
          y: 2,
        },
        text: "Tulln",
      },
      poi: {
        key: "rathaus-tulln",
        position: {
          x: 48,
          y: 10,
        },
        type: "historical",
        shortName: "Rathaus",
        images: [],
        poiKey: 257,
      },
    },
    end: {
      flag: {
        position: {
          x: 922,
          y: 428,
        },
      },
      arrow: null,
      name: {
        position: {
          x: -70,
          y: 50,
        },
        text: "Wien",
      },
      poi: {
        key: "united-nations-information-service",
        position: {
          x: 10,
          y: 60,
        },
        type: "historical",
        shortName: "Vereinte Nationen",
        images: [],
        poiKey: 267,
      },
    },
  },
};

function Route({ isShowInfo, setIsShowInfo }) {
  const [scale, setScale] = useState(0);
  const [below950, setBelow950] = useState(window.innerWidth <= 950);

  const [, setContent] = useState(null);
  const [currentStage, setCurrentStage] = useState(0);
  const [showEtappe, setShowEtappe] = useState(true);

  const [isShowAllPois, setIsShowAllPois] = useState(false);
  const [pois, setPois] = useState([]);
  const [finalEtappeConfig, setFinalEtappeConfig] = useState();

  const currentSlideState = useMemo(() => ({}), []);
  const fetchedServerData = useMemo(() => undefined, []);

  const [slideState, setSlideState] = useState(currentSlideState);
  const [dataState, setDataState] = useState(fetchedServerData);

  const containerRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [shiftAmount, setShiftAmount] = useState(0);

  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.left,
      y: e.clientY - position.top,
    });
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging) return;

      const newPosition = {
        top: e.clientY - offset.y,
        left: e.clientX - offset.x,
      };
      setPosition(newPosition);
    },
    [isDragging, offset.x, offset.y]
  );

  const handleMouseUp = useCallback(
    (e) => {
      setIsDragging(false);

      const newPosition = {
        top: e.clientY - offset.y,
        left: e.clientX - offset.x,
      };

      // console.log("Position:", newPosition);
    },
    [offset.x, offset.y]
  );

  // Add event listeners to handle mouse movement
  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp, isDragging]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    if (currentStage === 0) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentStage]);

  const shiftViewBox = useCallback(() => {
    const mapElement1 = document.querySelector(".map-big div:first-child svg");
    const mapElement2 = document.querySelector(".map-big div:nth-child(3) svg");
    if (mapElement1 && mapElement2) {
      const oldViewBox1 = mapElement1.getAttribute("viewBox");
      const oldViewBox2 = mapElement2.getAttribute("viewBox");

      const [, oldY1, oldWidth1, oldHeight1] = oldViewBox1
        .split(" ")
        .map(Number);
      const [, oldY2, oldWidth2, oldHeight2] = oldViewBox2
        .split(" ")
        .map(Number);

      let currentShiftAmount = 0;
      console.log(scrollY);
      if (scrollY > 300 && scrollY <= 600) {
        currentShiftAmount = (scrollY - 300) * 0.1;
      } else if (scrollY > 600 && scrollY <= 1200) {
        currentShiftAmount = (scrollY - 300) * 0.1 + (scrollY - 600) * 0.2;
      } else if (scrollY > 1200 && scrollY <= 1600) {
        currentShiftAmount =
          (scrollY - 300) * 0.1 +
          (scrollY - 600) * 0.2 +
          (scrollY - 1200) * 0.3;
      } else if (scrollY > 1600 && scrollY <= 2900) {
        currentShiftAmount =
          (scrollY - 300) * 0.1 +
          (scrollY - 600) * 0.2 +
          (scrollY - 1200) * 0.3 +
          (scrollY - 1600) * -0.5;
      } else if (scrollY > 2900 && scrollY <= 3150) {
        currentShiftAmount =
          (scrollY - 300) * 0.1 +
          (scrollY - 600) * 0.2 +
          (scrollY - 1200) * 0.3 +
          (scrollY - 1600) * -0.5 +
          (scrollY - 2900) * 2;
      } else if (scrollY > 3150 && scrollY <= 3500) {
        currentShiftAmount =
          (scrollY - 300) * 0.1 +
          (scrollY - 600) * 0.2 +
          (scrollY - 1200) * 0.3 +
          (scrollY - 1600) * -0.5 +
          (scrollY - 2900) * 2 +
          (scrollY - 3150) * -2.1;
      } else if (scrollY > 3500) {
        currentShiftAmount =
          (scrollY - 300) * 0.1 +
          (scrollY - 600) * 0.2 +
          (scrollY - 1200) * 0.3 +
          (scrollY - 1600) * -0.5 +
          (scrollY - 2900) * 2 +
          (scrollY - 3150) * -2.1 +
          (scrollY - 3500) * 2.8;
      }

      mapElement1.setAttribute(
        "viewBox",
        `${-750 + currentShiftAmount} ${oldY1} ${oldWidth1} ${oldHeight1}`
      );
      mapElement2.setAttribute(
        "viewBox",
        `${-750 + currentShiftAmount} ${oldY2} ${oldWidth2} ${oldHeight2}`
      );

      setShiftAmount(currentShiftAmount);
    }
  }, [scrollY]);

  useEffect(() => {
    shiftViewBox();
  }, [scrollY, shiftViewBox]);

  const setCurrentSlide = useCallback(
    (poi, idx) => {
      const currentSlideState = {};
      Object.assign(currentSlideState, slideState);
      currentSlideState[poi] = idx;

      setSlideState(currentSlideState);
    },
    [slideState]
  );

  const toggleShowAllPois = useCallback(() => {
    setIsShowAllPois(!isShowAllPois);
  }, [isShowAllPois]);

  const clickHandler = useCallback((event) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      !event.target.classList.contains("stop") &&
      !event.target.parentElement?.classList.contains("stop") &&
      !event.target.parentElement?.parentElement?.classList.contains("stop") &&
      !event.target.parentElement?.parentElement?.parentElement?.classList.contains(
        "stop"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
        "stop"
      ) &&
      !event.target.classList.contains("pois") &&
      !event.target.parentElement?.classList.contains("pois") &&
      !event.target.parentElement?.parentElement?.classList.contains("pois") &&
      !event.target.parentElement?.parentElement?.parentElement?.classList.contains(
        "pois"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
        "pois"
      ) &&
      !event.target.classList.contains("end") &&
      !event.target.parentElement?.classList.contains("end") &&
      !event.target.parentElement?.parentElement?.classList.contains("end") &&
      !event.target.parentElement?.parentElement?.parentElement?.classList.contains(
        "end"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
        "end"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
        "end"
      ) &&
      !event.target.classList.contains("start") &&
      !event.target.parentElement?.classList.contains("start") &&
      !event.target.parentElement?.parentElement?.classList.contains("start") &&
      !event.target.parentElement?.parentElement?.parentElement?.classList.contains(
        "start"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
        "start"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
        "start"
      ) &&
      !event.target.classList.contains("options-container") &&
      !event.target.parentElement?.classList.contains("options-container") &&
      !event.target.parentElement?.parentElement?.classList.contains(
        "options-container"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.classList.contains(
        "options-container"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
        "options-container"
      ) &&
      !event.target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
        "options-container"
      )
    ) {
      setIsShowInfo(false);
      document.removeEventListener("click", clickHandler);
    }
  }, []);

  useEffect(() => {
    const updateScale = () => {
      const vh = below950 ? window.screen.height : window.innerHeight;
      const vw = window.innerWidth;
      const scaleFactorEtappe1 =
        1 - (below950 ? 0.1 : 0) - (vh - 793) * 0.0001 + (vw - 1734) * 0;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-1",
        scaleFactorEtappe1
      );

      const scaleFactorEtappe2 = 0.9 - (vh - 793) * 0.0003 + (vw - 1734) * 0;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-2",
        scaleFactorEtappe2
      );

      const scaleFactorEtappe3 = 1 - (vh - 793) * 0 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-3",
        scaleFactorEtappe3
      );

      const scaleFactorEtappe4 =
        1 - (vh - 793) * 0.0001 + (vw - 1734) * 0.00015;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-4",
        scaleFactorEtappe4
      );

      const scaleFactorEtappe5 = 0.9 - (vh - 793) * 0 + (vw - 1734) * 0.0003;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-5",
        scaleFactorEtappe5
      );

      const scaleFactorEtappe6 = 0.9 - (vh - 793) * 0 + (vw - 1734) * 0.000275;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-6",
        scaleFactorEtappe6
      );

      const scaleFactorEtappe7 =
        1 - (vh - 793) * 0.00015 + (vw - 1734) * 0.0001;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-7",
        scaleFactorEtappe7
      );

      const scaleFactorEtappe8 = 0.9 - (vh - 793) * 0 + (vw - 1734) * 0.0001;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-8",
        scaleFactorEtappe8
      );

      const scaleFactorEtappe9 = 1 - (vh - 793) * 0 + (vw - 1734) * 0;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-9",
        scaleFactorEtappe9
      );

      const scaleFactorEtappe10 =
        0.9 - (vh - 793) * 0.0001 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-10",
        scaleFactorEtappe10
      );

      const scaleFactorEtappe11 =
        0.9 - (vh - 793) * 0.00025 + (vw - 1734) * 0.00035;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-11",
        scaleFactorEtappe11
      );

      const scaleFactorEtappe12 = 1 - (vh - 793) * 0 + (vw - 1734) * 0.0001;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-12",
        scaleFactorEtappe12
      );

      const scaleFactorEtappe13 = 0.85 - (vh - 793) * 0 + (vw - 1734) * 0.00015;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-13",
        scaleFactorEtappe13
      );

      const scaleFactorEtappe14 =
        1 - (vh - 793) * 0.0001 + (vw - 1734) * 0.0001;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-14",
        scaleFactorEtappe14
      );

      const scaleFactorEtappe15 = 1 - (vh - 793) * 0 + (vw - 1734) * 0.0004;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-15",
        scaleFactorEtappe15
      );

      const scaleFactorEtappe16 = 1 - (vh - 793) * 0 + (vw - 1734) * 0.0004;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-16",
        scaleFactorEtappe16
      );

      const scaleFactorEtappe17 = 1 - (vh - 793) * 0 + (vw - 1734) * 0.0003;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-17",
        scaleFactorEtappe17
      );

      const scaleFactorEtappe1_Open =
        1 - (vh - 793) * 0.00045 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-1-open",
        scaleFactorEtappe1_Open
      );

      const scaleFactorEtappe2_Open =
        0.9 - (vh - 793) * 0.00045 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-2-open",
        scaleFactorEtappe2_Open
      );

      const scaleFactorEtappe3_Open =
        1 - (vh - 793) * 0.00045 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-3-open",
        scaleFactorEtappe3_Open
      );

      const scaleFactorEtappe4_Open =
        1 - (vh - 793) * 0.00045 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-4-open",
        scaleFactorEtappe4_Open
      );

      const scaleFactorEtappe5_Open =
        0.9 - (vh - 793) * 0.000575 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-5-open",
        scaleFactorEtappe5_Open
      );

      const scaleFactorEtappe6_Open =
        0.8 - (vh - 793) * 0.0005 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-6-open",
        scaleFactorEtappe6_Open
      );

      const scaleFactorEtappe7_Open =
        1 - (vh - 793) * 0.0004 + (vw - 1734) * 0.0001;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-7-open",
        scaleFactorEtappe7_Open
      );

      const scaleFactorEtappe8_Open =
        0.9 - (vh - 793) * 0.0004 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-8-open",
        scaleFactorEtappe8_Open
      );

      const scaleFactorEtappe9_Open =
        1 - (vh - 793) * 0.0004 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-9-open",
        scaleFactorEtappe9_Open
      );

      const scaleFactorEtappe10_Open =
        0.9 - (vh - 793) * 0.0005 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-10-open",
        scaleFactorEtappe10_Open
      );

      const scaleFactorEtappe11_Open =
        0.7 - (vh - 793) * 0.0005 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-11-open",
        scaleFactorEtappe11_Open
      );

      const scaleFactorEtappe12_Open =
        1 - (vh - 793) * 0.0004 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-12-open",
        scaleFactorEtappe12_Open
      );

      const scaleFactorEtappe13_Open =
        0.85 - (vh - 793) * 0.00045 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-13-open",
        scaleFactorEtappe13_Open
      );

      const scaleFactorEtappe14_Open =
        1 - (vh - 793) * 0.00045 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-14-open",
        scaleFactorEtappe14_Open
      );

      const scaleFactorEtappe15_Open =
        0.9 - (vh - 793) * 0.0006 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-15-open",
        scaleFactorEtappe15_Open
      );

      const scaleFactorEtappe16_Open =
        0.8 - (vh - 793) * 0.0005 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-16-open",
        scaleFactorEtappe16_Open
      );

      const scaleFactorEtappe17_Open =
        0.9 - (vh - 793) * 0.0004 + (vw - 1734) * 0.0002;
      document.documentElement.style.setProperty(
        "--scale-factor-etappe-17-open",
        scaleFactorEtappe17_Open
      );
    };

    window.addEventListener("resize", updateScale);

    return () => {
      window.removeEventListener("resize", updateScale);
    };
  }, [below950]);

  const showStage = useCallback((state, stageNumber) => {
    setShowEtappe(false);
    setCurrentStage(stageNumber === 18 ? 17 : stageNumber);
    setTimeout(() => {
      window.scrollTo({
        top: 0, // Scrollen ganz nach oben
        behavior: "smooth", // Optional: Weicher Übergang
      });
    }, 250);
  }, []);

  const toggleShowInfo = useCallback(
    (state, scrollPos, isFirst) => {
      let newState = state;
      if (typeof state === "undefined") {
        newState = !isShowInfo;
        setIsShowInfo(newState);
      } else {
        setIsShowInfo(state);
      }

      if (newState) {
        setTimeout(() => {
          document.addEventListener("click", clickHandler);
        }, 0);
      } else {
        document.removeEventListener("click", clickHandler);
      }

      if (scrollPos >= 0) {
        const target = document.getElementById(
          `etappe-${currentStage}-poi-${scrollPos}`
        );

        if (target) {
          const container = target.parentElement.parentElement;
          if (container) {
            container.scrollTo({
              top: isFirst ? 0 : target.offsetTop - container.offsetTop - 10,
              behavior: "smooth",
            });
          }
        }
      }
    },
    [isShowInfo, clickHandler, currentStage]
  );

  useEffect(() => {
    const handleResize = () => {
      const height = below950 ? window.screen.height : window.innerHeight;
      const scaleFactor = (height - (below950 ? 255 : 98)) / 695; // Adjust this factor based on your needs
      setScale(scaleFactor);

      if (window.innerWidth <= 950) {
        setBelow950(true);
      } else {
        setBelow950(false);
      }

      const vh = (below950 ? window.screen.height : window.innerHeight) * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // Set the initial scale
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [below950]);

  const animateViewBox = (oldViewBox, newViewBox, duration = 250) => {
    const [oldX, oldY, oldWidth, oldHeight] = oldViewBox.split(" ").map(Number);
    const [newX, newY, newWidth, newHeight] = newViewBox.split(" ").map(Number);

    const startTime = performance.now();

    function animate() {
      const currentTime = performance.now();
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const easeProgress = progress * (2 - progress); // ease-out function

      const currentX = oldX + (newX - oldX) * easeProgress;
      const currentY = oldY + (newY - oldY) * easeProgress;
      const currentWidth = oldWidth + (newWidth - oldWidth) * easeProgress;
      const currentHeight = oldHeight + (newHeight - oldHeight) * easeProgress;

      const mapElement1 = document.querySelector(
        ".map-big div:first-child svg"
      );
      const mapElement2 = document.querySelector(
        ".map-big div:nth-child(3) svg"
      );
      if (mapElement1 && mapElement2) {
        mapElement1.setAttribute(
          "viewBox",
          `${currentX} ${currentY} ${currentWidth} ${currentHeight}`
        );
        mapElement2.setAttribute(
          "viewBox",
          `${currentX} ${currentY} ${currentWidth} ${currentHeight}`
        );
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }

    requestAnimationFrame(animate);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const mapElement1 = document.querySelector(
        ".map-big div:first-child svg"
      );
      const mapElement2 = document.querySelector(
        ".map-big div:nth-child(3) svg"
      );
      if (mapElement1 && mapElement2 && finalEtappeConfig) {
        const oldViewBox1 = mapElement1.getAttribute("viewBox");
        const oldViewBox2 = mapElement1.getAttribute("viewBox");
        const newViewBox = finalEtappeConfig.config.viewBox;
        animateViewBox(oldViewBox1, newViewBox);
        animateViewBox(oldViewBox2, newViewBox);
        setTimeout(() => {
          setShowEtappe(true);
        }, 500);
        mapElement1.style.opacity = 1;
        mapElement2.style.opacity = 1;
        clearInterval(intervalId);
      } else if (mapElement1 && mapElement2 && !finalEtappeConfig) {
        const oldViewBox1 = mapElement1.getAttribute("viewBox");
        const oldViewBox2 = mapElement2.getAttribute("viewBox");
        const newViewBox = routeConfig.etappe0.config.viewBox;
        animateViewBox(oldViewBox1, newViewBox);
        animateViewBox(oldViewBox2, newViewBox);
        setTimeout(() => {
          setShowEtappe(true);
        }, 500);
        mapElement1.style.opacity = 1;
        mapElement2.style.opacity = 1;
        clearInterval(intervalId);
      }
    }, 100);
  }, [finalEtappeConfig]);

  useEffect(() => {
    const init = async () => {
      const map = window.document.querySelector(".map");
      if (map) {
        map.classList.add("visible");
      }
      const line = window.document.querySelector(".line");
      if (line) {
        line.classList.add("visible");
      }

      let data;
      if (dataState) {
        data = dataState;
      } else {
        const res = await fetch(
          `${
            process.env.NODE_ENV === "production"
              ? ""
              : `http://${window.location.hostname}:3011`
          }/pois`
        );
        data = await res.json();
        setDataState(data);
      }

      const pioData = data;
      pioData.data.forEach((stage) => {
        stage.attributes.point_of_interests.data.forEach((poi) => {
          currentSlideState[`${poi.attributes.poiNumber}`] = 0;
        });
      });

      const stageData = pioData.data.find(
        (stage) => stage.attributes.stageNumber === currentStage
      );

      if (stageData) {
        const startPoi = stageData.attributes.point_of_interests.data.find(
          (poi) =>
            poi.attributes.title.startsWith("S1") ||
            poi.attributes.title.startsWith(
              `E${currentStage - 1}S${currentStage}`
            )
        );

        const endPoi = stageData.attributes.point_of_interests.data.find(
          (poi) =>
            poi.attributes.title.startsWith("E17") ||
            poi.attributes.title.startsWith(
              `E${currentStage}S${currentStage + 1}`
            )
        );
        const offshorePois =
          stageData.attributes.point_of_interests.data.filter((poi) =>
            poi.attributes.title.startsWith("O")
          );
        const stoppPois = stageData.attributes.point_of_interests.data.filter(
          (poi) => poi.attributes.title.startsWith("P")
        );

        if (startPoi) {
          routeConfig[`etappe${currentStage}`].start.poi.isStart = true;
          routeConfig[`etappe${currentStage}`].start.poi.key =
            startPoi.attributes.pioKey;
          routeConfig[`etappe${currentStage}`].start.poi.poiKey =
            startPoi.attributes.poiNumber;
          routeConfig[`etappe${currentStage}`].start.poi.type =
            startPoi.attributes.pioType;
          routeConfig[`etappe${currentStage}`].start.poi.name =
            startPoi.attributes.title
              .replace(/E[0-9]{1,2}S[0-9]{1,2} /, "")
              .replace(/S1 /, "");
          routeConfig[`etappe${currentStage}`].start.poi.images =
            startPoi.attributes.images.data.map((img) => ({
              url: img.attributes.url,
              title: img.attributes.caption,
              footnote: `${
                sources[
                  img.attributes.alternativeText?.replace(
                    /\[([0-9]{1,}[a-z]{0,2})\]/g,
                    "$1"
                  )
                ]
              }`,
            }));
          routeConfig[`etappe${currentStage}`].start.poi.text =
            startPoi.attributes.description;
        }

        if (endPoi) {
          routeConfig[`etappe${currentStage}`].end.poi.isEnd = true;
          routeConfig[`etappe${currentStage}`].end.poi.key =
            endPoi.attributes.pioKey;
          routeConfig[`etappe${currentStage}`].end.poi.poiKey =
            endPoi.attributes.poiNumber;
          routeConfig[`etappe${currentStage}`].end.poi.type =
            endPoi.attributes.pioType;
          routeConfig[`etappe${currentStage}`].end.poi.name =
            endPoi.attributes.title
              .replace(/E[0-9]{1,2}S[0-9]{1,2} /, "")
              .replace(/E17 /, "");
          routeConfig[`etappe${currentStage}`].end.poi.images =
            endPoi.attributes.images.data.map((img) => ({
              url: img.attributes.url,
              title: img.attributes.caption,
              footnote: `${
                sources[
                  img.attributes.alternativeText?.replace(
                    /\[([0-9]{1,}[a-z]{0,2})\]/g,
                    "$1"
                  )
                ]
              }`,
            }));
          routeConfig[`etappe${currentStage}`].end.poi.text =
            endPoi.attributes.description;
        }

        if (offshorePois?.length) {
          offshorePois.forEach((offshorePoi) => {
            const foundOffshorePoi = routeConfig[
              `etappe${currentStage}`
            ].pois.find(
              (poi) => poi.poiKey === offshorePoi.attributes.poiNumber
            );

            if (foundOffshorePoi) {
              foundOffshorePoi.isOffshore = true;
              foundOffshorePoi.key = offshorePoi.attributes.pioKey;
              foundOffshorePoi.poiKey = offshorePoi.attributes.poiNumber;
              foundOffshorePoi.type = offshorePoi.attributes.pioType;
              foundOffshorePoi.name = offshorePoi.attributes.title.replace(
                /O /,
                ""
              );

              foundOffshorePoi.images = offshorePoi.attributes.images.data.map(
                (img) => ({
                  url: img.attributes.url,
                  title: img.attributes.caption,
                  footnote: `${
                    sources[
                      img.attributes.alternativeText?.replace(
                        /\[([0-9]{1,}[a-z]{0,2})\]/g,
                        "$1"
                      )
                    ]
                  }`,
                })
              );
              foundOffshorePoi.text = offshorePoi.attributes.description;
            }
          });
        }

        if (stoppPois?.length) {
          stoppPois.forEach((stoppPoi) => {
            const foundStoppPoi = routeConfig[
              `etappe${currentStage}`
            ].stopps.find(
              (poi) => poi.poiKey === stoppPoi.attributes.poiNumber
            );

            if (foundStoppPoi) {
              foundStoppPoi.isStopp = true;
              foundStoppPoi.key = stoppPoi.attributes.pioKey;
              foundStoppPoi.poiKey = stoppPoi.attributes.poiNumber;
              foundStoppPoi.type = stoppPoi.attributes.pioType;
              foundStoppPoi.name = stoppPoi.attributes.title.replace(/P /, "");

              foundStoppPoi.images = stoppPoi.attributes.images.data.map(
                (img) => ({
                  url: img.attributes.url,
                  title: img.attributes.caption,
                  footnote: `${
                    sources[
                      img.attributes.alternativeText?.replace(
                        /\[([0-9]{1,}[a-z]{0,2})\]/g,
                        "$1"
                      )
                    ]
                  }`,
                })
              );
              foundStoppPoi.text = stoppPoi.attributes.description;
            }
          });
        }

        const finalPois = []
          .concat(routeConfig[`etappe${currentStage}`].start.poi)
          .concat(routeConfig[`etappe${currentStage}`].pois)
          .concat(routeConfig[`etappe${currentStage}`].stopps)
          .concat(routeConfig[`etappe${currentStage}`].end.poi);

        finalPois.sort((itemA, itemB) => {
          let aItem = itemA.poiKey === 0 ? 1.5 : itemA.poiKey;
          let bItem = itemB.poiKey === 0 ? 1.5 : itemB.poiKey;
          return aItem - bItem;
        });

        setPois(finalPois);

        routeConfig[`etappe${currentStage}`].distanceLength =
          stageData.attributes.distanceInKm;
        routeConfig[`etappe${currentStage}`].durationInHours =
          stageData.attributes.durationInHours;
        routeConfig[`etappe${currentStage}`].title = stageData.attributes.title
          .replace(/[0-9]{1,2}. /, "")
          .trim()
          .split("|")[0];
        routeConfig[`etappe${currentStage}`].time = stageData.attributes.title
          .replace(/[0-9]{1,2}. /, "")
          .trim()
          .split("|")[1];
        setFinalEtappeConfig(routeConfig[`etappe${currentStage}`]);

        setContent(stageData);
      }
    };

    init();
  }, [currentSlideState, currentStage, dataState]);

  return (
    <div
      className={`tour-route-container ${currentStage === 0 ? "etappe-0" : ""}`}
    >
      {currentStage !== 0 ? (
        <div
          className={`options-container ${isShowInfo ? "open" : ""}`}
          onClick={toggleShowAllPois}
        >
          <div className={`options-checkbox${isShowAllPois ? " active" : ""}`}>
            <div className="icon">
              <ReactSVG src={cross} />
            </div>
          </div>
          <div className={`options-label`}>Zeige weitere POIs</div>
        </div>
      ) : null}
      {currentStage !== 0 ? (
        <div
          className={`back-container ${isShowInfo ? "open" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            const mapElement1 = document.querySelector(
              ".map-big div:first-child svg"
            );
            const mapElement2 = document.querySelector(
              ".map-big div:nth-child(3) svg"
            );
            if (mapElement1 && mapElement2) {
              mapElement1.setAttribute("viewBox", `-750 600 2508 3660`);
              mapElement2.setAttribute("viewBox", `-750 600 2508 3660`);

              setShiftAmount(0);
            }

            setShowEtappe(false);
            setIsShowInfo(false);
            setCurrentStage(0);
            setFinalEtappeConfig(null);
          }}
        >
          <div className="icon">
            <ReactSVG src={arrowEtappenD} />
          </div>
          Zur Übersicht
        </div>
      ) : null}
      {finalEtappeConfig ? (
        <div
          ref={containerRef}
          className={`info-container ${isShowInfo ? " visible" : ""}`}
        >
          <div className={`info-button-container`}>
            {currentStage === 1 && (
              <div className="sign-up-button">
                <Link reloadDocument to={{ pathname: "/join" }}>
                  <div className="icon">
                    <ReactSVG src={arrowTeamD} />
                  </div>
                  anmelden und mitfahren
                </Link>
              </div>
            )}
            {false && (
              <div className="komoot-button">
                <a
                  target="_blank"
                  href={etappeInfos[`e${currentStage}`].komootSrc}
                  rel="noreferrer"
                >
                  <div className="icon">
                    <ReactSVG src={komootD} />
                  </div>
                  Etappe auf Komoot
                </a>
              </div>
            )}
            <div className="close-icon" onClick={() => toggleShowInfo(false)}>
              <ReactSVG src={arrowRight} />
            </div>
          </div>
          <div className={`info-scroll-container`}>
            <div className={`info-etappe-container`}>
              <div className={`info-etappe-name`}>
                <div className="text">Etappe {currentStage}</div>
                <div className="time">{finalEtappeConfig.time}</div>
              </div>
              <div className={`info-etappe-title`}>
                {finalEtappeConfig.title}
              </div>
              <div className={`info-etappe-details`}>
                <div className="content-left-info-left">
                  <ReactSVG src={arrowRight} />
                  <span>{finalEtappeConfig.distanceLength} km</span>
                </div>
                <div className="content-left-info-right">
                  <ReactSVG src={routeTime} />
                  <span>{finalEtappeConfig.durationInHours} Stunden</span>
                </div>
              </div>
            </div>
            <div className="poi-container">
              {pois.map((poi) => (
                <div
                  className="poi-item"
                  id={`etappe-${currentStage}-poi-${poi.poiKey}`}
                  key={`etappe-${currentStage}-poi-${poi.poiKey}`}
                >
                  <div
                    className={`info-etappe-poi-title${
                      poi.isOffshore ? " is-offshore" : ""
                    }${poi.isStopp ? " is-stopp" : ""}${
                      poi.isEnd ? " is-end" : ""
                    }${poi.isStart ? " is-start" : ""}`}
                  >
                    <div className={`icon`}>
                      {poi.isOffshore && <ReactSVG src={arrowOLocationD} />}
                      {poi.isStopp && <ReactSVG src={arrowPoiD} />}
                      {(poi.isStart || poi.isEnd) && (
                        <ReactSVG src={arrowLocationD} />
                      )}
                    </div>
                    <div className="text">{poi.name}</div>
                  </div>
                  <div className={`info-etappe-image-slider`}>
                    <Slider
                      onSlideComplete={(i) => {
                        setCurrentSlide(poi.poiKey, i);
                      }}
                      activeIndex={slideState[poi.poiKey]}
                      threshHold={100}
                      transition={0.25}
                    >
                      {poi.images.map(({ url, title }) => (
                        <div className="image-container" key={url}>
                          <img src={url} key={url} alt={title} />
                          {poi.images[slideState[poi.poiKey]]?.footnote && (
                            <div className="footnote-text">
                              {poi.images[slideState[poi.poiKey]].footnote}
                            </div>
                          )}
                        </div>
                      ))}
                    </Slider>
                    {poi.images[slideState[poi.poiKey]]?.title && (
                      <div className="caption-text">
                        {poi.images[slideState[poi.poiKey]].title}
                      </div>
                    )}
                  </div>
                  <div className="info-etappe-image-slider-indicator">
                    <div className="indicator-container">
                      <div className="dots">
                        {poi.images.map(({ url }, idx) => (
                          <div
                            key={url}
                            onClick={() => setCurrentSlide(poi.poiKey, idx)}
                            className={`dot${
                              slideState[poi.poiKey] === idx ? " active" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={`info-etappe-poi-text`}>{poi.text}</div>
                </div>
              ))}
            </div>
            <div className={`info-etappe-container`}>
              <div className={`info-etappe-name`}>
                <div className="text">Etappe {currentStage}</div>
                <div className="time">{finalEtappeConfig.time}</div>
              </div>
              <div className={`info-etappe-title`}>
                {finalEtappeConfig.title}
              </div>
              <div className={`info-etappe-details`}>
                <div className="content-left-info-left">
                  <ReactSVG src={arrowRight} />
                  <span>{finalEtappeConfig.distanceLength} km</span>
                </div>
                <div className="content-left-info-right">
                  <ReactSVG src={routeTime} />
                  <span>{finalEtappeConfig.durationInHours} Stunden</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={`map-big etappe-${currentStage} ${
          isShowInfo ? " open" : ""
        }`}
      >
        <ReactSVG src={mapRoute} />
        <div className={`overlay-layer ${isShowInfo ? "open" : ""}`} />
        <ReactSVG src={map} />
        {!finalEtappeConfig ? (
          <div
            className={`etappe-container etappe-0 visible ${
              isShowInfo ? "open" : ""
            }`}
            style={{
              top: `${routeConfig.etappe0.config.topBase}px`,
              left: `${routeConfig.etappe0.config.leftBase}px`,
              transform: `translateX(${
                -1 * shiftAmount - shiftAmount * 0.13
              }px)`,
            }}
          >
            {routeConfig.etappe0.stages.map((stage) => (
              <div
                key={`stage-list-${stage.stageNumber}`}
                className={`stage${
                  stage.position.isRight ? " is-right" : ""
                } stage-${stage.stageNumber}`}
                style={{
                  left: `${stage.position.x}px`,
                  top: `${stage.position.y}px`,
                }}
                onClick={() => showStage(true, stage.stageNumber)}
              >
                <div className="icon-number">{stage.stageNumber}</div>
                <div className={`name`}>{stage.name}</div>
              </div>
            ))}
          </div>
        ) : null}
        {finalEtappeConfig ? (
          <div
            className={`etappe-container etappe-${
              finalEtappeConfig.stageNumber
            }${showEtappe ? " visible" : ""} ${isShowInfo ? "open" : ""}`}
            style={{
              transform: `scale(${scale})`,
              top: `calc(${finalEtappeConfig.config.topBase}px - (((${scale} - 1) * 10) * ${finalEtappeConfig.config.topOffset}px))`,
              left: `calc(${finalEtappeConfig.config.leftBase}px + (((${scale} - 1) * 10) * ${finalEtappeConfig.config.leftOffset}px))`,
            }}
          >
            <div
              className="start"
              style={{
                left: `${finalEtappeConfig.start.flag.position.x}px`,
                top: `${finalEtappeConfig.start.flag.position.y}px`,
              }}
              onClick={() =>
                toggleShowInfo(true, finalEtappeConfig.start.poi.poiKey, true)
              }
            >
              <div
                className="icon"
                onClick={() =>
                  toggleShowInfo(true, finalEtappeConfig.start.poi.poiKey, true)
                }
              >
                <ReactSVG src={arrowLocationD} />
              </div>
              <div
                className="name"
                style={{
                  left: `${finalEtappeConfig.start.name.position.x}px`,
                  top: `${finalEtappeConfig.start.name.position.y}px`,
                }}
                onClick={() =>
                  toggleShowInfo(true, finalEtappeConfig.start.poi.poiKey, true)
                }
              >
                {finalEtappeConfig.start.name.text}
              </div>
              <div
                className="poi"
                style={{
                  left: `${finalEtappeConfig.start.poi.position.x}px`,
                  top: `${finalEtappeConfig.start.poi.position.y}px`,
                }}
                onClick={() =>
                  toggleShowInfo(true, finalEtappeConfig.start.poi.poiKey, true)
                }
              >
                <div className="poi-icon" />
                <div className="poi-name">
                  Start | {finalEtappeConfig.start.poi.shortName}
                </div>
              </div>
              {finalEtappeConfig.start.arrow && showEtappe && (
                <div
                  className={`arrow is-start`}
                  style={{
                    left: `${finalEtappeConfig.start.arrow.position.x}px`,
                    top: `${finalEtappeConfig.start.arrow.position.y}px`,
                    transform: `scale(0.6) rotate(${finalEtappeConfig.start.arrow.position.degrees}deg)`,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (routeConfig[`etappe${currentStage - 1}`]) {
                      setShowEtappe(false);
                      setCurrentStage(currentStage - 1);
                    }
                  }}
                >
                  <div className="arrow-icon" style={{}}>
                    <ReactSVG src={arrowRightNC} />
                  </div>
                  <div className="arrow-name">
                    zur {currentStage - 1}. Etappe
                  </div>
                </div>
              )}
            </div>
            <div
              className="end"
              style={{
                left: `${finalEtappeConfig.end.flag.position.x}px`,
                top: `${finalEtappeConfig.end.flag.position.y}px`,
              }}
            >
              <div
                className="icon"
                onClick={() =>
                  toggleShowInfo(true, finalEtappeConfig.end.poi.poiKey)
                }
              >
                <ReactSVG src={arrowLocationD} />
              </div>
              <div
                className="name"
                style={{
                  left: `${finalEtappeConfig.end.name.position.x}px`,
                  top: `${finalEtappeConfig.end.name.position.y}px`,
                }}
                onClick={() =>
                  toggleShowInfo(true, finalEtappeConfig.end.poi.poiKey)
                }
              >
                {finalEtappeConfig.end.name.text}
              </div>
              <div
                className="poi"
                style={{
                  left: `${finalEtappeConfig.end.poi.position.x}px`,
                  top: `${finalEtappeConfig.end.poi.position.y}px`,
                }}
                onClick={() =>
                  toggleShowInfo(true, finalEtappeConfig.end.poi.poiKey)
                }
              >
                <div className="poi-icon" />
                <div className="poi-name">
                  Ende | {finalEtappeConfig.end.poi.shortName}
                </div>
              </div>
              {finalEtappeConfig.end.arrow && showEtappe && (
                <div
                  className={`arrow ${
                    finalEtappeConfig.end.arrow.up ? "is-start" : "is-end"
                  }`}
                  style={{
                    left: `${finalEtappeConfig.end.arrow.position.x}px`,
                    top: `${finalEtappeConfig.end.arrow.position.y}px`,
                    transform: `scale(0.6) rotate(${finalEtappeConfig.end.arrow.position.degrees}deg)`,
                  }}
                  onClick={() => {
                    if (routeConfig[`etappe${currentStage + 1}`]) {
                      setShowEtappe(false);
                      setCurrentStage(currentStage + 1);
                    }
                  }}
                >
                  {finalEtappeConfig.end.arrow.up && (
                    <>
                      <div className="arrow-icon" style={{}}>
                        <ReactSVG src={arrowRightNC} />
                      </div>
                      <div className="arrow-name">
                        zur {currentStage + 1}. Etappe
                      </div>
                    </>
                  )}
                  {!finalEtappeConfig.end.arrow.up && (
                    <>
                      <div className="arrow-name">
                        zur {currentStage + 1}. Etappe
                      </div>
                      <div className="arrow-icon" style={{}}>
                        <ReactSVG src={arrowRightNC} />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <>
              <div
                className="stop"
                onMouseDown={handleMouseDown}
                style={{
                  display: "none",
                  position: "absolute",
                  top: `${position.top}px`,
                  left: `${position.left}px`,
                  cursor: "move",
                }}
              >
                <div className="icon">
                  <ReactSVG src={arrowPoiD} />
                </div>
                <div className={`name`}>test</div>
              </div>
              {finalEtappeConfig.stopps.map((stop) => (
                <div
                  key={`stopps-list-${stop.poiKey}`}
                  className="stop"
                  style={{
                    left: `${stop.position.x}px`,
                    top: `${stop.position.y}px`,
                  }}
                  onClick={() => toggleShowInfo(true, stop.poiKey)}
                >
                  <div className="icon">
                    <ReactSVG src={arrowPoiD} />
                  </div>
                  <div
                    className={`name${
                      stop.position.isRight ? " is-right" : ""
                    }`}
                  >
                    {stop.shortName}
                  </div>
                </div>
              ))}
            </>

            {finalEtappeConfig.pois.map((poi) => (
              <div
                key={`pois-list-${poi.poiKey}`}
                className={`pois${!isShowAllPois ? " hide" : ""}`}
                style={{
                  left: `${poi.position.x}px`,
                  top: `${poi.position.y}px`,
                }}
                onClick={() => toggleShowInfo(true, poi.poiKey)}
              >
                <div className="icon" />
                <div
                  className={`name${poi.position.isRight ? " is-right" : ""}`}
                >
                  {poi.shortName}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Route;
