import { useEffect, useState } from "react";
import * as edjsHTML from "editorjs-html";
import { sanitize } from "../../lib";

const edjsParser = edjsHTML();

function Team() {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const [currentImage, setCurrentImage] = useState();
  const [currentMainText, setCurrentMainText] = useState();
  const [currentMainTitle, setCurrentMainTitle] = useState();

  const [currentTeamMembers, setCurrentTeamMembers] = useState([]);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "production"
          ? ""
          : `http://${window.location.hostname}:3011`
      }/content-team`
    )
      .then((res) => res.json())
      .then((data) => {
        const rawData = data?.data?.attributes?.content;
        const parsedData = JSON.parse(rawData);
        const firstImage = parsedData?.blocks?.find(
          (item) => item.type === "image"
        )?.data?.file?.url;

        const firstImageIdx = parsedData?.blocks?.findIndex(
          (item) => item.type === "image"
        );
        if (firstImageIdx !== -1) {
          parsedData.blocks.splice(firstImageIdx, 1);
        }

        const teamMembers = [];
        const teamMemberArray = parsedData?.blocks?.filter(
          (item) =>
            (item.type === "paragraph" &&
              (item.data.text.indexOf("+team-member-title+") !== -1 ||
                item.data.text.indexOf("+team-member-text+") !== -1)) ||
            (item.type === "image" &&
              item.data.caption &&
              item.data.caption.indexOf("+team-member-image+") !== -1)
        );

        teamMemberArray.forEach((item, idx) => {
          if (
            item.type === "paragraph" &&
            item.data.text.indexOf("+team-member-title+") !== -1
          ) {
            teamMembers.push({
              title: sanitize(item.data?.text),
              text: sanitize(teamMemberArray[idx + 1]?.data?.text),
              image: teamMemberArray[idx + 2]?.data?.file?.url,
            });
          }
        });

        setCurrentTeamMembers(teamMembers);

        if (parsedData) {
          const html = edjsParser.parse(parsedData);
          setContent(
            html
              .filter((block) => typeof block === "string")
              .filter((block) => block.indexOf("+title+") === -1)
              .filter((block) => block.indexOf("+title-text+") === -1)
              .filter((block) => block.indexOf("+team-member-title+") === -1)
              .filter((block) => block.indexOf("+team-member-text+") === -1)
              .join("")
          );
        }

        setLoading(false);

        setCurrentImage(firstImage);

        setCurrentMainText(
          sanitize(
            parsedData?.blocks?.find((item) => item.type === "paragraph")?.data
              ?.text
          )
        );

        setCurrentMainTitle(
          sanitize(
            parsedData?.blocks?.find((item) => item.type === "header")?.data
              ?.text
          )
        );
      });
  }, []);

  return (
    <div className="container">
      {!isLoading ? (
        <div className="Team-main">
          {currentImage ? (
            <div className="main-image">
              <img src={currentImage} alt="Team" />
            </div>
          ) : null}
          {currentMainText ? (
            <div className="main-head">
              <div className="main-head-content">
                <div className="main-head-text-container">
                  <div className="main-head-title">
                    <h2
                      dangerouslySetInnerHTML={{ __html: currentMainTitle }}
                    />
                  </div>
                  <div className="main-head-text">
                    <p dangerouslySetInnerHTML={{ __html: currentMainText }} />
                  </div>
                </div>
                <div className="main-head-line" />
              </div>
            </div>
          ) : null}
          <div className="main-container">
            <div className="main-content-title">
              <h1>Unser Team</h1>
            </div>
            <div className="main-content-text">
              {currentTeamMembers.map((currentTeamMember) => (
                <div
                  key={currentTeamMember.title}
                  className="team-member-item-container"
                >
                  <div className="team-member-item-image">
                    <img
                      src={currentTeamMember.image}
                      alt={currentTeamMember.title}
                    />
                  </div>
                  <div className="team-member-item-text-container">
                    <div className="team-member-item-text-title">
                      <h3>{currentTeamMember.title}</h3>
                    </div>
                    <div className="team-member-item-text-text">
                      {currentTeamMember.text}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="main-content-line" />
            <div className="main-content-line-2" />
          </div>
        </div>
      ) : null}
      <div
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}

export default Team;
