import { useEffect, useState } from "react";
import * as edjsHTML from "editorjs-html";

const edjsParser = edjsHTML();

function Sponsors() {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "production"
          ? ""
          : `http://${window.location.hostname}:3011`
      }/content-sponsors`
    )
      .then((res) => res.json())
      .then((data) => {
        const rawData = data?.data?.attributes?.content;
        const parsedData = JSON.parse(rawData);
        if (parsedData) {
          const html = edjsParser.parse(parsedData);
          const sponsorBtnBlockIdx = html.findIndex(
            (block) => block.indexOf("+sponsor-button+") !== -1
          );
          if (sponsorBtnBlockIdx !== -1) {
            html[
              sponsorBtnBlockIdx
            ] = `<div class="call-to-action"><a href="/sponsoring">${html[
              sponsorBtnBlockIdx
            ].replace("+sponsor-button+", "")}</a></div>`;
          }

          setContent(
            html.filter((block) => typeof block === "string").join("")
          );
        }

        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      <div className="Partners-main">
        {!isLoading ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : null}
      </div>
    </div>
  );
}

export default Sponsors;
