import { useEffect, useState } from "react";
import * as edjsHTML from "editorjs-html";
import { sanitize } from "../../lib";

const edjsParser = edjsHTML();

function Sponsors() {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const [currentImage, setCurrentImage] = useState();
  const [currentMainText, setCurrentMainText] = useState();
  const [currentMainTitle, setCurrentMainTitle] = useState();

  const [currentSponsorBadges, setCurrentSponsorBadges] = useState([]);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "production"
          ? ""
          : `http://${window.location.hostname}:3011`
      }/content-un`
    )
      .then((res) => res.json())
      .then((data) => {
        const rawData = data?.data?.attributes?.content;
        const parsedData = JSON.parse(rawData);
        const firstImage = parsedData?.blocks?.find(
          (item) => item.type === "image"
        )?.data?.file?.url;

        const firstImageIdx = parsedData?.blocks?.findIndex(
          (item) => item.type === "image"
        );
        if (firstImageIdx !== -1) {
          parsedData.blocks.splice(firstImageIdx, 1);
        }

        const sponsorBadges = [];
        const sponsorBadgeArray = parsedData?.blocks?.filter(
          (item) =>
            (item.type === "paragraph" &&
              (item.data.text.indexOf("+sponsor-badge-title+") !== -1 ||
                item.data.text.indexOf("+sponsor-badge-text+") !== -1 ||
                item.data.text.indexOf("+sponsor-badge-list+") !== -1)) ||
            (item.type === "image" &&
              item.data.caption &&
              (item.data.caption.indexOf("+sponsor-badge-image+") !== -1 ||
                item.data.caption.indexOf("+sponsor-badge-partner-image+") !==
                  -1))
        );

        sponsorBadgeArray.forEach((item, idx) => {
          if (
            item.type === "paragraph" &&
            item.data.text.indexOf("+sponsor-badge-title+") !== -1
          ) {
            sponsorBadges.push({
              title: sanitize(item.data?.text),
              text: sanitize(sponsorBadgeArray[idx + 1]?.data?.text),
              list: sanitize(sponsorBadgeArray[idx + 2]?.data?.text),
              image: sponsorBadgeArray[idx + 3]?.data?.file?.url,
              partners: [
                {
                  partnerImage:
                    sponsorBadgeArray[idx + 4]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 4]?.data?.file?.url
                      : null,
                  partnerLink:
                    sponsorBadgeArray[idx + 4]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 4]?.data?.caption.split(
                          "+sponsor-badge-partner-image+"
                        )[1]
                      : null,
                },
                (idx === 5 || idx === 12) && {
                  partnerImage:
                    sponsorBadgeArray[idx + 5]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 5]?.data?.file?.url
                      : null,
                  partnerLink:
                    sponsorBadgeArray[idx + 5]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 5]?.data?.caption.split(
                          "+sponsor-badge-partner-image+"
                        )[1]
                      : null,
                },
                (idx === 5 || idx === 12) && {
                  partnerImage:
                    sponsorBadgeArray[idx + 6]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 6]?.data?.file?.url
                      : null,
                  partnerLink:
                    sponsorBadgeArray[idx + 6]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 6]?.data?.caption.split(
                          "+sponsor-badge-partner-image+"
                        )[1]
                      : null,
                },
                idx === 12 && {
                  partnerImage:
                    sponsorBadgeArray[idx + 7]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 7]?.data?.file?.url
                      : null,
                  partnerLink:
                    sponsorBadgeArray[idx + 7]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 7]?.data?.caption.split(
                          "+sponsor-badge-partner-image+"
                        )[1]
                      : null,
                },
                idx === 12 && {
                  partnerImage:
                    sponsorBadgeArray[idx + 8]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 8]?.data?.file?.url
                      : null,
                  partnerLink:
                    sponsorBadgeArray[idx + 8]?.data?.caption?.indexOf(
                      "+sponsor-badge-partner-image+"
                    ) !== -1
                      ? sponsorBadgeArray[idx + 8]?.data?.caption.split(
                          "+sponsor-badge-partner-image+"
                        )[1]
                      : null,
                },
              ].filter((item) => !!item.partnerImage),
            });
          }
        });

        setCurrentSponsorBadges(sponsorBadges);

        if (parsedData) {
          const html = edjsParser.parse(parsedData);
          const mailtoIdx = html.findIndex(
            (block) => block.indexOf("mailto:") !== -1
          );
          if (mailtoIdx !== -1) {
            const subject = "Interesse an Sponsoring - Bike the Line 2025";
            html[mailtoIdx] = html[mailtoIdx].replace(
              /(mailto:joerg.tuermer@biketheline.de)/,
              `$1?subject=${subject}`
            );
          }
          setContent(
            html
              .filter((block) => typeof block === "string")
              .filter((block) => block.indexOf("+title+") === -1)
              .filter((block) => block.indexOf("+title-text+") === -1)
              .filter((block) => block.indexOf("+sponsor-badge-title+") === -1)
              .filter((block) => block.indexOf("+sponsor-badge-text+") === -1)
              .filter((block) => block.indexOf("+sponsor-badge-list+") === -1)
              .join("")
          );
        }

        setLoading(false);

        setCurrentImage(firstImage);

        setCurrentMainText(
          parsedData?.blocks
            ?.filter(
              (item) =>
                item.type === "paragraph" &&
                item.data?.text?.indexOf("+title-text+") !== -1
            )
            .map((item) => {
              if (item.data?.text.indexOf("mailto:") !== -1) {
                const subject = "Interesse an Sponsoring - Bike the Line 2025";
                return sanitize(
                  `<p>${item.data?.text.replace(
                    /(mailto:joerg.tuermer@biketheline.de)/,
                    `$1?subject=${subject}`
                  )}</p>`
                );
              }

              return sanitize(`<p>${item.data?.text}</p>`);
            })
            .join("")
        );

        setCurrentMainTitle(
          sanitize(
            parsedData?.blocks?.find(
              (item) =>
                item.type === "header" &&
                item.data?.text?.indexOf("+title+") !== -1
            )?.data?.text
          )
        );
      });
  }, []);

  return (
    <div className="container">
      {!isLoading ? (
        <div className="Sponsors-main">
          {currentImage ? (
            <div className="main-image">
              <img src={currentImage} alt="Sponsoren" />
            </div>
          ) : null}
          {currentMainText ? (
            <div className="main-head">
              <div className="main-head-content">
                <div className="main-head-text-container">
                  <div className="main-head-title">
                    <h2
                      dangerouslySetInnerHTML={{ __html: currentMainTitle }}
                    />
                  </div>
                  <div
                    className="main-head-text"
                    dangerouslySetInnerHTML={{ __html: currentMainText }}
                  ></div>
                </div>
                <div className="main-head-line" />
              </div>
            </div>
          ) : null}
          <div className="main-container">
            <div className="main-content-title">
              <h1>Unsere Sponsoringebenen</h1>
            </div>
            <div className="main-content-text">
              {currentSponsorBadges.map((currentSponsorBadge, idx) => (
                <div
                  key={`${currentSponsorBadge.title}_${idx}`}
                  className="sponsor-badge-item-container"
                >
                  <div className="sponsor-badge-item-image">
                    <img
                      src={currentSponsorBadge.image}
                      alt={currentSponsorBadge.title}
                    />
                  </div>
                  <div className="sponsor-badge-item-text-container">
                    <div className="sponsor-badge-item-text-title">
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: currentSponsorBadge.title,
                        }}
                      />
                    </div>
                    <div
                      className="sponsor-badge-item-text-text is-bold"
                      dangerouslySetInnerHTML={{
                        __html: currentSponsorBadge.text,
                      }}
                    />
                    <div
                      className="sponsor-badge-item-text-list"
                      dangerouslySetInnerHTML={{
                        __html: currentSponsorBadge.list,
                      }}
                    />
                    <div
                      className={`sponsor-badge-item-partner-list ${
                        idx === 1 ? " is-gold" : idx === 2 ? " is-silver" : ""
                      }`}
                    >
                      {currentSponsorBadge.partners
                        ? currentSponsorBadge.partners.map((partner, pIdx) => (
                            <div
                              key={`${partner.partnerImage}_${pIdx}`}
                              className={`sponsor-badge-item-partner-list-item${
                                idx === 1
                                  ? " is-gold"
                                  : idx === 2
                                  ? " is-silver"
                                  : ""
                              } ${
                                partner.partnerImage?.indexOf("Placeholder") ===
                                -1
                                  ? " is-active"
                                  : ""
                              }`}
                            >
                              <a
                                href={partner.partnerLink}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <img src={partner.partnerImage} alt="Partner" />
                              </a>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="main-content-line" />
            <div className="main-content-line-2" />
          </div>
        </div>
      ) : null}
      <div
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}

export default Sponsors;
