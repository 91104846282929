import { useCallback, useEffect, useState } from "react";

const isValidEmail = (email) => {
  // This regex checks for a pattern like: any-non-space characters before and after the @ and a dot.
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

function Join() {
  const [isError, setIsError] = useState(false);
  const [hasDataError, setDataError] = useState(undefined);
  const [token, setToken] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleCaptchaChange = useCallback((token) => {
    setToken(token);
  }, []);

  useEffect(() => {
    if (window.handleCaptchaResponse) {
      const oldHandleCaptchaResponse = window.handleCaptchaResponse;
      window.handleCaptchaResponse = (token) => {
        handleCaptchaChange(token);
        oldHandleCaptchaResponse(token);
      };
    }
  });

  const sendJoin = useCallback(() => {
    fetch(
      `${
        process.env.NODE_ENV === "production"
          ? ""
          : `http://${window.location.hostname}:3011`
      }/jointour`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: document.getElementById("EMAIL").value,
          name: document.getElementById("NAME").value,
          token,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setSuccess(true);
        }
      });
  }, [token]);

  const checkForm = useCallback(
    (e) => {
      const checkbox = document.getElementById("OPT_IN");
      if (!checkbox.checked) {
        e.preventDefault();
        e.stopPropagation();
        setIsError(true);
      } else {
        setIsError(false);
        const email = document.getElementById("EMAIL");
        const name = document.getElementById("NAME");

        let error = undefined;
        if (!name?.value) {
          error = "Bitte gib deinen Vor- und Nachnamen an.";
        }
        if (!email?.value || !isValidEmail(email.value)) {
          error = "Bitte gib eine valide E-Mail-Adresse an.";
        }
        if (!token) {
          error = "Bitte benutze das reCAPTCHA.";
        }

        setDataError(error);
        e.preventDefault();
        e.stopPropagation();
        if (!error) {
          sendJoin();
        }
      }
    },
    [sendJoin, token]
  );

  return (
    <div className="container">
      <div className="Join-main">
        <div className="main-content-headline">
          <h1>Sei dabei - bei der 1. Etappe von "Bike the Line"!</h1>
        </div>
        <div className="main-content-text">
          <p>
            Am 27. Juni starten wir die Gedenk-Radtour „Bike the Line“ von der
            KinderUni Wismar zur KinderUni Wien und weiter zu den Vereinten
            Nationen. Sei dabei bei der Auftakt-Etappe von Wismar nach Schwerin,
            wenn wir gemeinsam ein Zeichen für Frieden und Erinnerungskultur
            setzen!
          </p>
          <p>
            Unser Event ist inklusiv und offen für alle – auch Sportler*innen
            mit Behinderung sind herzlich eingeladen, teilzunehmen.
          </p>
          <p>
            Ablauf der ersten Etappe:
            <br />
            <ul>
              <li>
                📌 15:00 - 15:45 Uhr – Erste Kinder-Vorlesung zum Thema
                „Vereinte Nationen“ (Info: 16:30 Uhr Zweite Kinder-Vorlesung)
              </li>
              <li>📌 16:00 Uhr – Grußwort vom Bürgermeister Thomas Beyer</li>
              <li>
                📌 16:30 Uhr – Start von „Bike the Line“ auf dem Campus
                (Fahrradbegleitung durch ADFC Wismar)
              </li>
              <li>
                📌 17:15 Uhr – Fotostopp an der Stadtvilla „Montgomery“
                (Vogelsang 9) – Ende der offiziellen Begleitung durch
                Kinderstudierende
              </li>
              <li>
                📌 17:30 Uhr – Abfahrt nach Klein Trebbow (24 km, ca. 1:20 h)
              </li>
              <li>
                📌 19:00 - 19:30 Uhr – Erfrischungspause am Gedenkort Teehaus
                Klein Trebbow
              </li>
              <li>
                📌 20:15 Uhr – Ankunft am Hauptbahnhof Schwerin und Ende der
                ersten Etappe
              </li>
            </ul>
          </p>
          <p>
            <b>ℹ️ Wichtiger Hinweis:</b>
            <br />
            <br />
            Nach dem offiziellen Ende am Schweriner Hauptbahnhof ist die
            Rückfahrt individuell zu organisieren. Bitte beachtet, dass die
            Fahrradmitnahme in Zügen der DB nur begrenzt möglich ist – also
            informiert euch frühzeitig!
            <br />
            <br />
            Weitere Details zur Mitfahrt werden noch zwischen ADFC, KinderUni
            Wismar und dem Bike the Line-Team abgestimmt und können ggf. noch
            geringe Anpassungen erfahren.
            <br />
            <br />
            Eine Begleitung auf den anschließenden Etappen im Norden ist aus
            organisatorischen und logistischen Gründen nicht vorgesehen.
          </p>
          <p>
            <b>💙 Wir freuen uns auf euch!</b>
          </p>
          <p>
            Damit wir besser planen können, bitten wir um eine Anmeldung im u.a.
            Formular. Die Teilnahme ist kostenlos.
          </p>
        </div>
        <div className="form-content">
          <div className="form-title">
            <h3>ANMELDUNG</h3>
          </div>
          {success && (
            <div>
              <p>
                Vielen Dank für deine Anmeldung. Wir senden dir in Kürze eine
                Bestätigungsmail!
              </p>
              <p>
                Unterstütze uns gern auf{" "}
                <a
                  target="_blank"
                  href="https://www.betterplace.org/p146632"
                  rel="noreferrer"
                >
                  betterplace.org
                </a>{" "}
                mit einer Spende für unser Projekt.
              </p>
              <div className="call-to-action left">
                <a
                  title="Jetzt spenden für „Bike the Line - Gedenk-Radtour 2025“ auf betterplace.org!"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.betterplace.org/de/projects/146632-bike-the-line-gedenk-radtour-2025?utm_campaign=donate_btn&amp;utm_content=project%23146632&amp;utm_medium=external_banner&amp;utm_source=projects"
                >
                  <h3>
                    Jetzt spenden
                    <br />
                    auf betterplace.org
                  </h3>
                </a>
              </div>
            </div>
          )}
          {!success && (
            <div className="form-container">
              <div
                className="sib-form"
                style={{
                  textAlign: "center",
                  marginTop: "8px",
                }}
              >
                <form method="POST" action="/jointour">
                  <div style={{ padding: "8px 0" }}>
                    <div
                      className="sib-form-block"
                      style={{
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#3C4858",
                        backgroundColor: "transparent",
                      }}
                    >
                      <div className="sib-text-form-block">
                        <p>Melde dich mit deinen Daten bei der 1. Etappe an.</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sib-input sib-form-block">
                      <div
                        className="form__entry entry_block"
                        style={{ textAlign: "left" }}
                      >
                        <div className="form__label-row">
                          <label
                            className="entry__label"
                            style={{
                              margin: "0",
                              fontWeight: "700",
                              textAlign: "left",
                              fontSize: "16px",
                              color: "#3c4858",
                            }}
                            htmlFor="EMAIL"
                            data-required="*"
                          >
                            E-Mail-Adresse
                          </label>

                          <div className="entry__field">
                            <input
                              className="input "
                              type="text"
                              id="EMAIL"
                              name="EMAIL"
                              autoComplete="off"
                              placeholder="E-Mail z.B. abc@xyz.com"
                              data-required="true"
                              required
                              style={{
                                color: "#000000",
                              }}
                            />
                          </div>

                          <label
                            className="entry__label"
                            style={{
                              margin: "0",
                              fontWeight: "700",
                              textAlign: "left",
                              fontSize: "16px",
                              color: "#3c4858",
                            }}
                            htmlFor="EMAIL"
                            data-required="*"
                          >
                            Vor- und Nachname
                          </label>

                          <div className="entry__field">
                            <input
                              className="input "
                              type="text"
                              id="NAME"
                              name="NAME"
                              autoComplete="off"
                              placeholder="Vor- und Nachname z.B. Max Mustermann"
                              data-required="true"
                              required
                              style={{
                                color: "#000000",
                              }}
                            />
                          </div>
                        </div>

                        <label
                          className="entry__error entry__error--primary entry__error--secondary"
                          style={{
                            height: "0px",
                            fontSize: "16px",
                            lineHeight: "20px",
                            textAlign: "left",
                            color: "#661d1d",
                            backgroundColor: "#ffeded",
                            borderColor: "#ff4949",
                          }}
                        ></label>
                        <label
                          className="entry__error entry__error--primary"
                          style={{
                            height: "0px",
                            display: isError ? "initial" : "none",
                            fontSize: "16px",
                            lineHeight: "20px",
                            textAlign: "left",
                            color: "#661d1d",
                            backgroundColor: "#ffeded",
                            borderColor: "#ff4949",
                          }}
                        >
                          Bitte nimm die Datenschutzerklärung an.
                        </label>
                      </div>
                    </div>
                  </div>
                  <label
                    className="test"
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#661d1d",
                      backgroundColor: "#ffeded",
                      borderColor: "#ff4949",
                      marginBottom: "8px",
                      marginLeft: "18px",
                      display: !!hasDataError ? "block" : "none",
                    }}
                  >
                    {!!hasDataError && <span>{hasDataError}</span>}
                  </label>
                  <div>
                    <div className="sib-optin sib-form-block">
                      <div className="form__entry entry_mcq">
                        <div className="form__label-row ">
                          <div
                            className="entry__choice"
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <label>
                              <input
                                type="checkbox"
                                className="input_replaced"
                                value="1"
                                onChange={() => {}}
                                id="OPT_IN"
                                name="OPT_IN"
                                required
                                data-required="true"
                              />
                              <span
                                className="checkbox checkbox_tick_positive"
                                style={{ marginLeft: 0 }}
                              ></span>
                              <span
                                style={{
                                  fontSize: "14px",
                                  textAlign: "left",
                                  color: "#3C4858",
                                  backgroundColor: "transparent",
                                }}
                              >
                                <p>Ich akzeptiere die Datenschutzerklärung.</p>
                                <span
                                  data-required="*"
                                  style={{ display: "inline" }}
                                  className="entry__label entry__label_optin"
                                ></span>
                              </span>
                            </label>
                          </div>
                        </div>
                        <label
                          className="entry__error entry__error--primary"
                          style={{
                            fontSize: "16px",
                            textAlign: "left",
                            color: "#661d1d",
                            backgroundColor: "#ffeded",
                            borderColor: "#ff4949",
                          }}
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sib-captcha sib-form-block">
                      <div className="form__entry entry_block">
                        <div className="form__label-row ">
                          <div
                            className="g-recaptcha sib-visible-recaptcha"
                            id="sib-captcha"
                            data-sitekey="6LdaQDgqAAAAAJJkSDuMKWxhmfBrbQDCijOpQEGi"
                            data-callback="handleCaptchaResponse"
                            style={{ direction: "ltr" }}
                          ></div>
                        </div>
                        <label
                          className="entry__error entry__error--primary"
                          style={{
                            fontSize: "16px",
                            textAlign: "left",
                            color: "#661d1d",
                            backgroundColor: "#ffeded",
                            borderColor: "#ff4949",
                          }}
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "8px 0" }}>
                    <div
                      className="sib-form-block"
                      style={{ textAlign: "right" }}
                    >
                      <button
                        className="sib-form-block__button sib-form-block__button-with-loader"
                        onClick={(e) => checkForm(e)}
                        style={{
                          fontSize: "14px",
                          textAlign: "right",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          backgroundColor: "#e34d3c",
                          borderRadius: "20px",
                          borderWidth: "0px",
                        }}
                        form="sib-form"
                        type="submit"
                      >
                        <svg
                          className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                          viewBox="0 0 512 512"
                        >
                          <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                        </svg>
                        ANMELDEN
                      </button>
                    </div>
                  </div>

                  <input
                    type="text"
                    name="email_address_check"
                    value=""
                    onChange={() => {}}
                    className="input--hidden"
                  />
                  <input
                    type="hidden"
                    name="locale"
                    value="de"
                    onChange={() => {}}
                  />
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Join;
