import { useEffect, useState } from "react";
import * as edjsHTML from "editorjs-html";

const edjsParser = edjsHTML();

function DataSecurity() {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (window?.initalize && !window.isInitialized) {
      window.initalize();

      const scriptTag = document.createElement("script");
      scriptTag.src =
        "https://biketheline.matomo.cloud/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=1";
      scriptTag.setAttribute(
        "nonce",
        document
          .querySelector('meta[name="csp-nonce"]')
          ?.getAttribute("content")
      );
      scriptTag.addEventListener("load", () => {
        window.isInitialized = true;
      });
      document.body.appendChild(scriptTag);
    }
  }, []);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "production"
          ? ""
          : `http://${window.location.hostname}:3011`
      }/content-data-security`
    )
      .then((res) => res.json())
      .then((data) => {
        const rawData = data?.data?.attributes?.content;
        const parsedData = JSON.parse(rawData);
        if (parsedData) {
          const html = edjsParser.parse(parsedData);
          setContent(
            html.filter((block) => typeof block === "string").join("")
          );
        }

        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      <div className="DataSecurity-main">
        {!isLoading ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : null}
        <div id="matomo-opt-out"></div>
      </div>
    </div>
  );
}

export default DataSecurity;
