import { ReactSVG } from "react-svg";
import arrowRight from "../../assets/icon_arrow4_zinnoberrot.svg";
import arrowUp from "../../assets/icon_arrow_zinnoberrot.svg";
import arrowEtappen from "../../assets/icon_etappen_zinnoberrot.svg";
import arrowEtappenD from "../../assets/icon_etappen_zinnoberrot_d.svg";
import arrowLocation from "../../assets/icon_location_zinnoberrot.svg";
import arrowLocationD from "../../assets/icon_location_zinnoberrot_d.svg";
import arrowTeamD from "../../assets/icon_team_zinnoberrot_d.svg";
import logoRehaZentrum from "../../assets/logo_reha_zentrum.svg";
import logoMacoHc from "../../assets/logo_maco_hc.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LogoUN from "../../assets/UN80_Logo.png";
import LogoInsta from "../../assets/instagram_logo.png";
import LogoYouT from "../../assets/yt_icon_rgb.png";
import LogoFaceB from "../../assets/Facebook_Logo_Primary.png";
import LogoTikTok from "../../assets/TikTok_Icon_Black_Circle.png";

const YouTubeEmbed = ({ videoId }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  return (
    <div className="video-container">
      <iframe
        width="560"
        height="315"
        src={embedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

function Home() {
  const [scrollDone, setScrollDone] = useState(false);
  useEffect(() => {
    if (window?.location?.hash === "#newsletter" && !scrollDone) {
      setTimeout(() => {
        const element = document.getElementById("newsletter");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          setScrollDone(true);
        }
      }, 1000);
    }
  }, [scrollDone]);

  return (
    <div className="home-container">
      <div className="headlines-container">
        <div className="main-headline">
          80 Jahre Ende
          <br />
          des Zweiten Weltkriegs
        </div>
        <div className="sub-headline">Gedenk-Radtour 2025</div>
        <div className="un-headline">
          <img src={LogoUN} alt="UN" />
        </div>
      </div>
      <div className="content-grid">
        <div className="content-left">
          <div className="content-left-top">
            <div className="content-left-title">
              <div className="content-left-maintitle">
                von<h1>WISMAR</h1>nach<h1>WIEN</h1>
              </div>
              <div className="content-left-subtitle">
                entlang der <b>DEMARKATIONSLINIE</b>
                <br />
                vom <b>MAI 1945</b>
              </div>
            </div>

            <div className="content-left-info">
              <div className="content-left-info-left">
                <ReactSVG src={arrowRight} />
                <span>ca. 1.400 km</span>
              </div>
              <div className="content-left-info-right">
                <ReactSVG src={arrowUp} />
                <span>ca. 9.500 hm</span>
              </div>
              <div className="content-left-info-left">
                <ReactSVG src={arrowEtappen} />
                <span>
                  17 Etappen in 18 Tagen
                  <br />
                  zu den Vereinten Nationen
                </span>
              </div>
              <div className="content-left-info-right">
                <ReactSVG src={arrowLocation} />
                <span>80 Gedenkorte</span>
              </div>
            </div>
          </div>
          <div className="main-trailer-container-mobile">
            <div className="main-trailer-mobile">
              <YouTubeEmbed videoId="4rWZL2C6xyY" />
            </div>
          </div>
          <div className="content-left-bottom">
            <div className="content-left-bottom-title">
              <h2>
                Tour-Start
                <br />
                am 27. Juni 2025
              </h2>
            </div>
            <div className="content-left-text">
              <p>
                Wir starten unsere Fahrradtour von der KinderUni Wismar zur
                UNO-City nach Wien.
              </p>
              <h3>Sei bei der 1. Etappe dabei!</h3>
              <p>
                Die erste Etappe ist als öffentliche Fahrt geplant und wird
                durch den ADFC Wismar organisert.
                <br />
                <br />
                es geht los <b>um 16:30 Uhr</b>
                <br />
                <b>am Hochschulcampus in Wismar</b>
              </p>
              <div className="sign-up-button">
                <Link reloadDocument to={{ pathname: "/join" }}>
                  <div className="icon">
                    <ReactSVG src={arrowTeamD} />
                  </div>
                  anmelden und mitfahren
                </Link>
              </div>
            </div>
          </div>
          <div className="content-left-bottom-2">
            <div className="content-left-bottom-title">
              <h2>Folgt uns auf unseren Kanälen!</h2>
            </div>
            <div className="content-left-text">
              <p>
                Seid bei unserer Tour live dabei und folgt uns auf Instagram.
                Wenn es losgeht, werden wir täglich berichten und unsere
                Eindrücke mit euch teilen.
              </p>
            </div>
            <div className="content-left-button content social-media">
              <a
                target="_blank"
                href="https://www.instagram.com/biketheline"
                rel="noreferrer"
              >
                <div className="insta-icon">
                  <img src={LogoInsta} alt="Instagram" />
                </div>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@BiketheLine"
                rel="noreferrer"
              >
                <div className="youtube-icon">
                  <img src={LogoYouT} alt="Youtube" />
                </div>
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61574426686076"
                rel="noreferrer"
              >
                <div className="facebook-icon">
                  <img src={LogoFaceB} alt="Facebook" />
                </div>
              </a>
              <a
                target="_blank"
                href="https://www.tiktok.com/@biketheline"
                rel="noreferrer"
              >
                <div className="tiktok-icon">
                  <img src={LogoTikTok} alt="Facebook" />
                </div>
              </a>
            </div>
            <div className="content-left-text">
              <p>
                Um nichts zu verpassen, meldet euch bei unserem Newsletter an.
              </p>
            </div>
            <div className="content-left-button">
              <a href="#newsletter">Zum Newsletter</a>
            </div>
          </div>
        </div>
        <div className="content-right">
          <div className="content-right-top">
            <div className="content-right-content-title">
              <h2>Was ist die Demarkationslinie?</h2>
            </div>
            <div className="content-right-image">
              <div className="content-right-image-circle meeting-line" />
              <div className="content-right-content-text">
                <p>
                  An der <b>Demarkationslinie</b> – auch Elbe-Mulde-Linie
                  genannt – trafen im Mai 1945 die westlichen Alliierten auf die
                  Rote Armee. Am 8. Mai endete der Zweite Weltkrieg in Europa.
                </p>
                <div className="clearfix"></div>
                <p>
                  Der Handschlag von Torgau symbolisiert den Friedenswillen und
                  nur wenige Wochen später wurden die Vereinten Nationen
                  gegründet.
                </p>
              </div>
            </div>
            <div className="content-right-content">
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/tour" }}>
                  <div className="icon">
                    <ReactSVG src={arrowEtappenD} />
                  </div>
                  Zur Route
                </Link>
              </div>
            </div>
          </div>
          <div className="content-right-middle">
            <div className="content-right-content-title">
              <h2>Was ist das Ziel?</h2>
            </div>
            <div className="content-right-image">
              <div className="content-right-image-circle mission" />
              <div className="content-right-content-text">
                <p>
                  Mit unserer <b>Gedenk-Radtour</b> entlang der
                  Demarkationslinie verbinden wir Gedenkorte und Menschen.
                </p>
                <p>
                  Wir machen Geschichte erlebbar und schlagen symbolisch Brücken
                  von Gestern zu Heute.
                </p>
                <p>
                  Wir errinnern an das Ende des Zweiten Weltkriegs und der
                  Gründung der Vereinten Nationen vor 80 Jahren.
                </p>
                <div className="clearfix"></div>
                <p>
                  Wir setzen ein aktives und sichtbares Zeichen für Frieden in
                  Europa und der Welt. Wir schaffen ein zeitloses und
                  nachhaltiges Fahrraderlebnis und laden zum Nachfahren ein.
                </p>
              </div>
            </div>
            <div className="content-right-content">
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/mission" }}>
                  <div className="icon">
                    <ReactSVG src={arrowLocationD} />
                  </div>
                  Zur Mission
                </Link>
              </div>
            </div>
          </div>
          <div className="content-right-bottom">
            <div className="content-right-content-title">
              <h2>Wer sind wir?</h2>
            </div>
            <div className="content-right-image">
              <div className="content-right-image-circle team" />
              <div className="content-right-content-text">
                <p>
                  Wir sind fünf radsport- und geschichtsbegeisterte
                  Familienväter. Wir stehen für{" "}
                  <b>Frieden und Völkerverständigung</b> und wollen mit unserem
                  Projekt einen bildungspolitischen Beitrag leisten.
                </p>
                <div className="clearfix"></div>
                <p>Erfahrt mehr über uns auf unserer Team-Seite.</p>
              </div>
            </div>
            <div className="content-right-content">
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/team" }}>
                  <div className="icon">
                    <ReactSVG src={arrowTeamD} />
                  </div>
                  Zum Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-trailer-container">
        <div className="main-trailer">
          <YouTubeEmbed videoId="4rWZL2C6xyY" />
        </div>
      </div>
      <div className="main-sponsoring-container">
        <div className="main-sponsoring">
          <h2>Unser Sponsoring</h2>
          <p>
            Ermöglicht wird der Erfolg maßgeblich durch die Unterstützung
            unterschiedlichster Sponsoren und Spender.
            <br />
            <br />
            Durch Sponsoring und Spenden wird die Organisation, die Durchführung
            und die Medienarbeit des Projekts auf ein professionelles Level
            gehoben und unsere Botschaft bekommt die Aufmerksamkeit, die sie
            verdient.
            <br />
            <br />
            Auf unserer <a href="/sponsoring">Sponsoring-Seite</a> erfährst du
            mehr, wie du uns als Sponsor bzw. Spender unterstützen kannst.
            <br />
            <br />
            Ebenso haben wir zusammen mit unserem Partner betterplace.org eine
            Crowdfunding-Kampagne an den Start gebracht, bei welcher uns jeder
            auch mit kleinen Spenden unterstützen kann.
          </p>
          <div className="call-to-action">
            <a
              title="Jetzt spenden für „Bike the Line - Gedenk-Radtour 2025“ auf betterplace.org!"
              target="_blank"
              rel="noreferrer"
              href="https://www.betterplace.org/de/projects/146632-bike-the-line-gedenk-radtour-2025?utm_campaign=donate_btn&amp;utm_content=project%23146632&amp;utm_medium=external_banner&amp;utm_source=projects"
            >
              <h3>
                Jetzt spenden
                <br />
                auf betterplace.org
              </h3>
            </a>
          </div>
          <div className="main-sponsoring-element-container">
            <div className="main-sponsoring-element-title">
              <h2>Platin-Sponsor</h2>
            </div>
            <div className="main-sponsoring-element-image">
              <div className="ribbon">
                <a href="/sponsoring">
                  noch verfügbar ★ ★ ★ ★<br />
                  Jetzt Platin-Sponsor werden
                </a>
              </div>
              <div className="ribbon is-mobile">
                <a href="/sponsoring">
                  Werde
                  <br />
                  Platin-Sponsor
                </a>
              </div>
              <img
                src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                alt="Platin-Sponsor"
                className="logo"
              />
            </div>
          </div>
          <div className="main-sponsoring-element-container is-gold">
            <div className="main-sponsoring-element-title">
              <h2>Gold-Sponsoren</h2>
            </div>
            <div className="main-sponsoring-element-image-container">
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★ ★<br />
                    Jetzt Gold-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-gold">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Gold-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Gold-Sponsor"
                  className="logo"
                />
              </div>
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★ ★<br />
                    Jetzt Gold-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-gold">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Gold-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Gold-Sponsor"
                  className="logo"
                />
              </div>
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★ ★<br />
                    Jetzt Gold-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-gold">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Gold-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Gold-Sponsor"
                  className="logo"
                />
              </div>
            </div>
          </div>
          <div className="main-sponsoring-element-container is-silver">
            <div className="main-sponsoring-element-title">
              <h2>Silber-Sponsoren</h2>
            </div>
            <div className="main-sponsoring-element-image-container">
              <div className="main-sponsoring-element-image is-active">
                <a
                  href={"https://www.radissonhotels.com/en-us/corporate"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1710104026/RHG_LOGO_RGB_608e6b7aca.jpg"
                    alt="Silber-Sponsor"
                    className="logo"
                  />
                </a>
              </div>
              <div className="main-sponsoring-element-image is-active">
                <a
                  href={"https://reha-schwerin.de/"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <ReactSVG src={logoRehaZentrum} />
                </a>
              </div>
              <div className="main-sponsoring-element-image is-active">
                <a
                  href={"https://homecompany-moebel.com/"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <ReactSVG src={logoMacoHc} />
                </a>
              </div>
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★<br />
                    Jetzt Silber-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-silver">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Silber-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Silber-Sponsor"
                  className="logo"
                />
              </div>
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★<br />
                    Jetzt Silber-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-silver">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Silber-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Silber-Sponsor"
                  className="logo"
                />
              </div>
            </div>
          </div>
          {/* <h2 className="second">Weitere Unterstützer</h2>
          <p>
            Weiter Sponsoren, Partner und Unterstützer findest du auf unser{" "}
            <a href="/partner">Partnerseite</a>.
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
