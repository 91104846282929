import MenuItem from "./MenuItem";

function Menu(props) {
  return (
    <div className="menu-container tk-koho">
      <MenuItem label="Home" active={props.currentPath === "/"} linkTo={"/"} />
      <MenuItem
        label="Mission"
        active={props.currentPath === "/mission"}
        linkTo={"/mission"}
      />
      <MenuItem
        label="Route"
        active={props.currentPath === "/tour"}
        linkTo={"/tour"}
      />
      <MenuItem
        label="Team"
        active={props.currentPath === "/team"}
        linkTo={"/team"}
      />
      <MenuItem
        label="Sponsoring"
        active={props.currentPath === "/sponsoring"}
        linkTo={"/sponsoring"}
      />
    </div>
  );
}

export default Menu;
