import { useEffect, useState } from "react";
import * as edjsHTML from "editorjs-html";
import Slideshow from "../Slideshow/Slideshow";

import Map1 from "../../assets/map_1.jpg";
import Map2 from "../../assets/map_2.jpg";
import Map3 from "../../assets/map_3.jpg";
import Map4 from "../../assets/map_4.jpg";
import Map5 from "../../assets/map_5.jpg";
import Map6 from "../../assets/map_6.jpg";
import Map7 from "../../assets/map_7.jpg";
import Map8 from "../../assets/map_8.jpg";
import Soldiers from "../../assets/Soldiers.jpg";
import BigMap from "../../assets/map-background-2.png";
import UNLogo from "../../assets/UN80_Logo.png";

const edjsParser = edjsHTML();

function Vision() {
  const [, setContent] = useState(null);
  const [, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "production"
          ? ""
          : `http://${window.location.hostname}:3011`
      }/content-vision`
    )
      .then((res) => res.json())
      .then((data) => {
        const rawData = data?.data?.attributes?.content;
        const parsedData = JSON.parse(rawData);
        if (parsedData) {
          const html = edjsParser.parse(parsedData);
          setContent(
            html.filter((block) => typeof block === "string").join("")
          );
        }

        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      <div className="Vision-main">
        <div className="main-content-headline">
          <h1>Was ist die Demarkationslinie?</h1>
        </div>
        <div className="main-content-line" />
        <div className="main-content-line-2" />
        <div className="main-content-line-3" />
        <div className="main-content-circle">
          <span className="main-content-circle-text">
            Am <b>8. Mai 1945</b>
            <br />
            endet der Zweite Weltkrieg in Europa. Die Alliierten treffen an
            verschiedenen Orten in ganz Mitteleuropa an der{" "}
            <b>Demarkationslinie</b>
            <br />
            aufeinander.
          </span>
        </div>
        <div className="main-content-circle-2">
          <span className="main-content-circle-text">
            <b>Am 26. Juni 1945</b>
            <br />
            wird die Charta der Vereinten Nationen unterzeichnet.
          </span>
          <img src={UNLogo} alt="UNLogo" />
          <span className="main-content-circle-text">
            An die Ereignisse vor 80 Jahren wollen wir erinnern und steigen
            auf's Rad
            <br />
            <b>- Bike the Line -</b>
          </span>
        </div>
        <div className="main-content-text-right">
          <span className="main-content-text-right-text">
            <b>
              "Frieden ist keine einmalige Errungenschaft; er ist ein
              fortwährender Prozess, der ständige Anstrengung und Hingabe
              erfordert."
            </b>{" "}
            <span className="main-content-text-right-subtext">
              <br />- Trygve Halvdan Lie -
            </span>
          </span>
        </div>
        <div className="slideshow-area">
          <Slideshow
            images={[Map1, Map2, Map3, Map4, Map5, Map6, Map7, Map8]}
            labels={[
              "Europa im Juni 1944 kurz vor dem D-Day in der Normandie",
              "Deutsche Gegenoffensive scheitert im Dezember 1944 in den Ardennen",
              "Im Januar 1945 Angriff der Roten Armee auf einer 1.200 km breiten Frontlinie",
              "An der Oder sammelt sich im März 1945 die Rote Armee für den Angriff auf Berlin",
              "Ebenso im März 1945 überschreiten US Truppen den Rhein bei Remagen",
              "Zusammenbruch der Westfront und Schlacht um Berlin im April 1945",
              "8. Mai 1945: Kapitulation und Vorrücken der Alliierten bis zur Demarkationslinie",
              "Bis Ende Mai 1945 schließen US Armee und Rote Armee an der Demarkationslinie auf",
            ]}
          />
        </div>
        <div className="main-image">
          <img src={Soldiers} alt="Soldiers" />
        </div>
        <div className="main-image-map">
          <img src={BigMap} alt="BigMap" />
        </div>
        {/* {!isLoading ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : null} */}
      </div>
    </div>
  );
}

export default Vision;
