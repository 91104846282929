import { Link } from "react-router-dom";
import PartnerLogo from "./PartnerLogo";

import LogoKAS from "../../assets/logo-kas.png";
import LogoDGVN from "../../assets/dgvnnord-logo-horizontal.png";
import LogoKUWS from "../../assets/FooterLogo_KinderUniWIsmar.png";
import LogoKUW from "../../assets/kinderuni_wien.png";
import LogoLPB from "../../assets/logo-lpbmv.png";
import LogoHSW from "../../assets/FooterLogo_HSW_designedat.svg";

function Partner() {
  return (
    <div className="partner-container">
      <div className="partners">
        <div className="partner-logos-container small">
          <PartnerLogo
            link="https://www.hs-wismar.de/hochschule/einrichtungen/familiengerechte-hochschule/veranstaltungen/kinderuni/"
            logoSrc={LogoKUWS}
            logoAlt="Kinder Uni Wismar"
          />
          <PartnerLogo
            link="https://www.dgvn-nord.de/"
            logoSrc={LogoDGVN}
            logoAlt="Deutsche Gesellschaft der Vereinten Nationen - LV Nord e.V."
          />
          <PartnerLogo
            link="https://kinderuni.at/kinderuniwien/"
            logoSrc={LogoKUW}
            logoAlt="Kinder Uni Wien"
          />
        </div>
        <div className="partner-logos-container desktop">
          <PartnerLogo
            link="https://www.lpb-mv.de/"
            logoSrc={LogoLPB}
            logoAlt="LPB-MV"
          />
          <PartnerLogo
            link="https://www.kas.de/de/web/mv"
            logoSrc={LogoKAS}
            logoAlt="Konrad Adenauer Stiftung"
          />
        </div>
        <div className="partner-logos-container mobile">
          <PartnerLogo
            link="https://www.lpb-mv.de/"
            logoSrc={LogoLPB}
            logoAlt="LPB-MV"
          />
          <PartnerLogo
            link="https://www.kas.de/de/web/mv"
            logoSrc={LogoKAS}
            logoAlt="Konrad Adenauer Stiftung"
          />
        </div>
        <div className="copyright-container">
          <div className="copyright">
            {`${"© Bike the Line"} ${new Date().getFullYear()}`}
          </div>
          <div className="link-collection">
            <Link reloadDocument to={{ pathname: "/impressum" }}>
              Impressum
            </Link>
            <div>|</div>
            <Link reloadDocument to={{ pathname: "/datenschutz" }}>
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
      <div className="designed-by">
        <PartnerLogo
          link="https://fg.hs-wismar.de/"
          logoSrc={LogoHSW}
          logoAlt="Hochschule Wismar - Fakultät Gestaltung"
        />
      </div>
    </div>
  );
}

export default Partner;
